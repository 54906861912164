<header class="absolute top-[50px] flex w-full justify-center">
  <mat-toolbar class="w-full bg-gray-50">
    <div class="m-auto flex w-full max-w-6xl justify-between text-sm font-bold tracking-tight text-gray-700">
      <xng-breadcrumb [separator]="iconTemplate">
        <ng-container *xngBreadcrumbItem="let breadcrumb">
          <ng-container>
            <div class="rounded-lg bg-gray-200 px-5 py-2">
              {{ breadcrumb | translate }}
            </div>
          </ng-container>
        </ng-container>
      </xng-breadcrumb>
    </div>
  </mat-toolbar>
</header>

<ng-template #iconTemplate>
  <mat-icon>arrow_right</mat-icon>
</ng-template>
