<header class="fixed top-0 z-[1000] flex w-full justify-center bg-blue-900">
  <mat-toolbar style="background-color: #0047ba; height: 50px" class="w-full max-w-screen-2xl">
    <div class="mx-auto flex w-[1280px] justify-between">
      <div class="flex items-center justify-center gap-x-1">
        <a routerLink="/livemaps">
          <img src="/img/logo_simple.svg" alt="." class="h-8 w-8" />
        </a>

        <app-auto-open-menu>
          <div trigger>{{ 'blocks.nav.CREATE' | translate }}</div>
          <div content>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/livemaps/new">
              {{ 'blocks.nav.MAP' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/lists/new">
              {{ 'blocks.nav.LIST' | translate }}
            </a>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/venues/new" *ngIf="featureFlags.VENUES">
              {{ 'blocks.nav.VENUES' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/points/new">
              {{ 'blocks.nav.POINT' | translate }}
            </a>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/trips/new">
              {{ 'blocks.nav.TRIP' | translate }}
            </a>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/areas/new">
              {{ 'blocks.nav.AREA' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/events/new">
              {{ 'blocks.nav.EVENT' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/assets/new">
              {{ 'blocks.nav.ASSET' | translate }}
            </a>
            <a
              class="block px-4 py-2 hover:bg-gray-100"
              *ngIf="user && user.hasFeature('portal')"
              routerLink="/portals/new">
              {{ 'blocks.nav.PORTAL' | translate }}
            </a>
          </div>
        </app-auto-open-menu>

        <app-auto-open-menu>
          <div trigger>{{ 'blocks.nav.MANAGE' | translate }}</div>
          <div content>
            <a class="block px-4 py-2 hover:bg-gray-100" [routerLink]="['/livemaps']" [queryParams]="{ page: 1 }">
              {{ 'blocks.nav.MY-MAPS' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a
              class="block px-4 py-2 hover:bg-gray-100"
              [routerLink]="['/lists']"
              [queryParams]="{ page: 1, state: 1 }">
              {{ 'blocks.nav.MY-LISTS' | translate }}
            </a>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/venues" *ngIf="featureFlags.VENUES">
              {{ 'blocks.nav.MY-VENUES' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a
              class="block px-4 py-2 hover:bg-gray-100"
              [routerLink]="['/points']"
              [queryParams]="{ page: 1, state: 1 }">
              {{ 'blocks.nav.MY-POINTS' | translate }}
            </a>
            <a
              class="block px-4 py-2 hover:bg-gray-100"
              [routerLink]="['/trips']"
              [queryParams]="{ page: 1, state: 1 }">
              {{ 'blocks.nav.MY-TRIPS' | translate }}
            </a>
            <a
              class="block px-4 py-2 hover:bg-gray-100"
              [routerLink]="['/areas']"
              [queryParams]="{ page: 1, state: 1 }">
              {{ 'blocks.nav.MY-AREAS' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a class="block px-4 py-2 hover:bg-gray-100" routerLink="/apps">
              {{ 'blocks.nav.MY-APPS' | translate }}
            </a>
            <a class="block px-4 py-2 hover:bg-gray-100" [routerLink]="['/portals']" [queryParams]="{ page: 1 }">
              {{ 'blocks.nav.MY-PORTALS' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a class="block px-4 py-2 hover:bg-gray-100" [routerLink]="['/events']" [queryParams]="{ page: 1 }">
              {{ 'blocks.nav.MY-EVENTS' | translate }}
            </a>
            <mat-divider></mat-divider>
            <a class="block px-4 py-2 hover:bg-gray-100" [routerLink]="['/assets']" [queryParams]="{ page: 1 }">
              {{ 'blocks.nav.MY-ASSETS' | translate }}
            </a>
          </div>
        </app-auto-open-menu>

        <a [routerLink]="['/explore']" [queryParams]="{ tab: 'points', page: 1 }">
          <button mat-flat-button class="w-24 font-extrabold" style="background-color: #0047ba; color: #fff">
            {{ 'blocks.nav.EXPLORE' | translate }}
          </button>
        </a>
      </div>

      <div class="flex items-center justify-center gap-x-1">
        <a *ngIf="user && user.hasFeature('statistics')" routerLink="/analytics">
          <button mat-flat-button class="w-24 font-extrabold" style="background-color: #0047ba; color: #fff">
            {{ 'blocks.nav.STATISTICS' | translate }}
          </button>
        </a>

        <button
          *ngIf="user && !user.hasFeature('statistics')"
          (click)="requestAccess('statistics')"
          mat-flat-button
          class="w-24 font-extrabold"
          style="background-color: #0047ba; color: #fff">
          {{ 'blocks.nav.STATISTICS' | translate }}
        </button>

        <app-auto-open-menu>
          <a trigger href="https://help.getwemap.com" target="_blank" class="text-white hover:text-white">
            {{ 'blocks.nav.SUPPORT' | translate }}
          </a>
          <div content>
            <a class="block px-4 py-2 hover:bg-gray-100" href="{{ doc_url }}" target="_blank">
              <span translate="blocks.header.DOC-DEVELOPERS"></span>
            </a>
            <a class="block px-4 py-2 hover:bg-gray-100" href="https://help.getwemap.com" target="_blank">
              <span translate="blocks.header.SUPPORT"></span>
            </a>
            <a class="block px-4 py-2 hover:bg-gray-100" href="/files/guide-de-prise-en-main.pdf" target="_blank">
              <span translate="blocks.header.GETTING-STARTED-GUIDE"></span>
            </a>
            <button
              class="block w-full cursor-pointer px-4 py-2 text-left hover:bg-gray-100"
              (click)="openWelcomeHelpModal()">
              <span translate="blocks.header.TUTORIAL"></span>
            </button>
          </div>
        </app-auto-open-menu>

        <div>
          <button
            mat-flat-button
            *ngIf="user"
            [matMenuTriggerFor]="menu"
            class="w-full font-extrabold"
            style="background-color: #0047ba; color: #fff">
            <div class="flex items-center justify-center gap-x-2">
              <img alt="User profile" [src]="user.photo_url" class="h-8 w-8 rounded-full" />
              {{ user.firstname }}
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
          </button>

          <mat-menu #menu="matMenu" class="options-menu">
            <h2 class="block px-3 pt-2 text-sm font-extrabold text-gray-700">
              {{ 'blocks.header.MY-ACCOUNT' | translate }}
            </h2>
            <a class="block px-4 py-2 text-sm hover:bg-gray-100" routerLink="/profile">
              {{ 'blocks.header.PROFILE' | translate }}
            </a>

            <a
              class="block px-4 py-2 text-sm hover:bg-gray-100"
              routerLink="/developer"
              *ngIf="user && user.hasFeature('api')">
              {{ 'breadcrumb.DEVELOPER-SETTINGS' | translate }}
            </a>

            <a class="block px-4 py-2 text-sm hover:bg-gray-100" routerLink="/users">
              {{ 'blocks.header.USERS' | translate }}
            </a>
            <mat-divider></mat-divider>

            <button
              class="block w-full cursor-pointer px-4 py-2 text-left text-sm hover:bg-gray-100"
              *ngIf="translate.currentLang !== 'fr'"
              (click)="setLang('fr')">
              Switch to French
            </button>

            <button
              class="block w-full cursor-pointer px-4 py-2 text-left text-sm hover:bg-gray-100"
              *ngIf="translate.currentLang !== 'en'"
              (click)="setLang('en')">
              Switch to English
            </button>

            <button
              class="block w-full cursor-pointer px-4 py-2 text-left text-sm hover:bg-gray-100"
              *ngIf="user && user.role === 1"
              (click)="openSwitchUserModal()">
              Switch user
            </button>

            <button
              class="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
              routerLink="sign-in"
              (click)="resetCredentials()">
              {{ 'blocks.header.LOGOUT' | translate }}
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-toolbar>
</header>
