import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigService } from './config/config.service';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  baseWidth = '1220px';

  TRACKER_ID: string;

  appConfig = {
    settings: {
      pinpointMode: 'List',
      eventMode: 'List',
      showTutorial: true,
      showNotificationHelp: true,
      createLivemap: true
    },
    firstTime: {
      livemapTour: true
    }
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private configService: ConfigService
  ) {
    this.loadSettingsFromLocalStorage();
    this.checkForAccessToken();
    this.initializeAnalyticsTracker();
  }

  get appSettings(): any {
    return this.appConfig.settings;
  }

  set appSettings(newSettings: any) {
    this.appConfig.settings = newSettings;
    this.saveSettings();
  }

  get appFirstTime(): any {
    return this.appConfig.firstTime;
  }

  set appFirstTime(newFirstTime: any) {
    this.appConfig.firstTime = newFirstTime;
    this.saveFirstTime();
  }

  setPinpointMode(mode: string): void {
    this.appConfig.settings.pinpointMode = mode;
    this.saveSettings();
  }

  setEventMode(mode: string): void {
    this.appConfig.settings.eventMode = mode;
    this.saveSettings();
  }

  setShowTutorial(show: boolean): void {
    this.appConfig.settings.showTutorial = show;
    this.saveSettings();
  }

  private loadSettingsFromLocalStorage(): void {
    const settings = localStorage.getItem('settings');
    if (settings) {
      this.appConfig.settings = JSON.parse(settings);
    }

    const firstTime = localStorage.getItem('firstTime');
    if (firstTime) {
      this.appConfig.firstTime = JSON.parse(firstTime);
    }
  }

  private checkForAccessToken(): void {
    const accessToken = localStorage.getItem('access_token');
    const currentPath = this.router.url;

    if (accessToken && (currentPath === '/sign-in' || currentPath === '')) {
      this.router.navigate(['/livemaps']);
    }
  }

  private saveSettings(): void {
    localStorage.setItem('settings', JSON.stringify(this.appConfig.settings));
  }

  private saveFirstTime(): void {
    localStorage.setItem('firstTime', JSON.stringify(this.appConfig.firstTime));
  }

  private initializeAnalyticsTracker(): void {
    const ANALYTICS = this.configService.get('ANALYTICS');
    if (ANALYTICS && ANALYTICS.CFETRACKER) {
      this.TRACKER_ID = ANALYTICS.CFETRACKER;
    }
  }
}
