<div class="title">WEMAP SAS © {{ currentYear }}</div>
<div class="terms-service">
  <a href="{{ tos }}">
    {{ 'page.FOOTER' | translate }}
  </a>
</div>

<div class="version">
  <span>{{ version }}</span>
</div>
