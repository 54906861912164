import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { EnsureModuleLoadOnceGuard } from '../guards/ensure-module-load-once.guard';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TranslateModule } from '@ngx-translate/core';
import { AutoOpenMenuComponent } from '~shared/auto-open-menu/auto-open-menu.component';
import { MaterialModule } from 'src/app/material.module';

import { BreadcrumbComponent as BreadcrumbModule, BreadcrumbItemDirective } from 'xng-breadcrumb';

const components = [HeaderComponent, FooterComponent, AutoOpenMenuComponent, BreadcrumbComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    MaterialModule,
    BreadcrumbModule,
    BreadcrumbItemDirective
  ],
  exports: [components],
  declarations: [components]
})
export class LayoutModule extends EnsureModuleLoadOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: LayoutModule) {
    super(parentModule);
  }
}
