import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';

import { AppConfigService } from '~services/app-config.service';
import { TutorialService } from '~services/tutorial/tutorial.service';
import { AuthService } from '~services/auth/auth.service';
import { UserService } from '~services/user/user.service';
import { ValidDomaine } from '../interfaces/config.interface';
import { NotificationService, NotificationType } from '../notification/notification.service';

@Component({
  selector: 'page-signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent {
  public forgotPassword: boolean;
  public loginClicked: boolean;
  public isSSO: boolean;

  private authError: string;

  private forgotSuccess: string;
  private forgotError: string;

  constructor(
    private appConfigService: AppConfigService,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private tutorialService: TutorialService,
    private userService: UserService,
    private notificationService: NotificationService
  ) {
    this.forgotPassword = false;
    this.loginClicked = false;
    this.isSSO = false;

    const url = new URL(window.location.href);
    const domaine = url.searchParams.get('domaine') as ValidDomaine;
    const code = url.searchParams.get('code');

    if (domaine && code) {
      this.loginSSO(domaine, code);
    }
  }

  afterLogin() {
    this.userService.initUser().then(
      () => {
        let storedUrl = localStorage.getItem('nextRoute');

        if (storedUrl) {
          storedUrl = decodeURIComponent(storedUrl);

          const [path, queryString] = storedUrl.split('?');
          const queryParams = {};

          if (queryString) {
            queryString.split('&').forEach((param) => {
              const [key, value] = param.split('=');
              queryParams[key] = value;
            });
          }

          localStorage.removeItem('nextRoute');
          this.router.navigate([path], { queryParams: queryParams });
        } else {
          this.router.navigate(['/livemaps'], { queryParams: { page: 1 } });
        }

        if (this.appConfigService.appSettings.showTutorial) {
          this.showTutorial();
        }
      },
      () => {
        this.loginClicked = false;
        this.authError = this.translateService.instant('auth.USER-FAILED');
        this.notificationService.launchNotification(NotificationType.Danger, this.authError);
      }
    );
  }

  loginLocal(signinForm: NgForm) {
    this.loginClicked = true;
    this.authError = undefined;

    this.authService
      .loginLocal(signinForm.value.username, signinForm.value.password)
      .then(() => {
        this.afterLogin();
      })
      .catch(() => {
        this.loginClicked = false;
        this.authError = this.translateService.instant('auth.ERROR-USERNAME-PASSWORD');
        this.notificationService.launchNotification(NotificationType.Danger, this.authError);
      });
  }

  redirectSSO(ssoForm: NgForm) {
    this.loginClicked = true;
    this.authError = undefined;

    this.authService.redirectSSO(ssoForm.value.domaine).catch(() => {
      this.loginClicked = false;
      this.authError = this.translateService.instant('auth.ERROR-SSO');
      this.notificationService.launchNotification(NotificationType.Danger, this.authError);
    });
  }

  loginSSO(domaine: ValidDomaine, code: string) {
    this.loginClicked = true;
    this.authError = undefined;

    this.authService
      .loginSSO(domaine, code)
      .then(() => {
        location.search = '';
        this.afterLogin();
      })
      .catch(() => {
        this.loginClicked = false;
        this.authError = this.translateService.instant('auth.ERROR-SSO');
      });
  }

  resetPassword(forgotForm: NgForm) {
    this.forgotError = undefined;
    this.forgotSuccess = undefined;

    this.userService.reset(forgotForm.value.email).then(
      () => {
        this.forgotPassword = false;
        this.forgotSuccess = this.translateService.instant('auth.SENT-EMAIL');
        this.notificationService.launchNotification(NotificationType.Success, this.forgotSuccess);
      },
      () => {
        this.forgotError = this.translateService.instant('auth.ERROR-SENT-EMAIL');
        this.notificationService.launchNotification(NotificationType.Danger, this.forgotError);
      }
    );
  }

  showTutorial() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => event.urlAfterRedirects.includes('/livemaps')),
        take(1)
      )
      .subscribe(() => {
        this.tutorialService.openTutorial();
        this.appConfigService.setShowTutorial(false);
      });
  }
}
