import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ConfigService } from '~services/config/config.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  nextRoute: string;

  constructor(
    private router: Router,
    private location: Location,
    private configService: ConfigService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.nextRoute = this.location.path();

        if (request.url.includes(this.configService.get('API_CONFIG').API_URL)) {
          if (err.status === 401 && this.router.url !== '/sign-in') {
            localStorage.setItem('nextRoute', this.nextRoute);
            this.router.navigate(['/sign-in']);
            return throwError(() => new Error('Wrong bearer token, please login'));
          } else if (err.status === 403) {
            this.router.navigate(['/unauthorized']);
            return throwError(() => new Error('Forbidden'));
          }
        }
        return throwError(() => err);
      })
    );
  }
}
