import { Component } from '@angular/core';
import { environment } from '~environments/environment';

// declare let APP_VERSION: string;

@Component({
  selector: 'app-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
  version = environment.version;
  tos = 'files/Terms_Of_Service_Wemap.pdf';
}
