import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { UserService } from '~services/user/user.service';

export const authGuard: CanActivateFn = async () => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);

  try {
    await userService.waitForAuthenticated();

    return true;
  } catch (error) {
    await router.navigate(['/sign-in']);

    return false;
  }
};
