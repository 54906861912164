import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { spreadsheetGuard } from './core/guards/spreadsheet.guard';
import { feedGuard } from './core/guards/feed.guard';

import { ErrorComponent } from './error/error.component';
import { SigninComponent } from './signin/signin.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    children: [
      { path: '', redirectTo: 'livemaps', pathMatch: 'full' },

      {
        path: 'assets',
        data: { preload: 'false' },
        loadChildren: () => import('./pages/assets/assets.module').then((m) => m.AssetsModule)
      },

      {
        path: 'livemaps',
        data: { preload: 'false' },
        loadChildren: () => import('./pages/livemaps/livemaps.module').then((m) => m.LivemapsModule)
      },

      {
        path: 'lists',
        data: { preload: false },
        loadChildren: () => import('./pages/lists/lists.module').then((m) => m.ListsModule)
      },

      {
        path: 'points',
        data: { preload: 'false' },
        loadChildren: () => import('./pages/pinpoints/pinpoints.module').then((m) => m.PinpointsModule)
      },

      {
        path: 'portals',
        data: { preload: 'false' },
        loadChildren: () => import('./pages/portal/portal.module').then((m) => m.PortalModule)
      },

      {
        path: 'events',
        data: { preload: 'false' },
        loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsModule)
      },

      {
        path: 'trips',
        data: {
          preload: false
        },
        loadChildren: () => import('./pages/trips/trips.module').then((m) => m.TripsModule)
      },

      {
        path: 'areas',
        data: { preload: 'false' },
        loadChildren: () => import('./pages/areas/areas.module').then((m) => m.AreasModule)
      },

      {
        path: 'feeds',
        canActivate: [feedGuard],
        data: { preload: 'false' },
        loadChildren: () => import('./pages/feeds/feeds.module').then((m) => m.FeedsModule)
      },

      {
        path: 'apps',
        canActivate: [feedGuard],
        data: {
          preload: false
        },
        loadChildren: () => import('./pages/apps/apps.module').then((m) => m.AppsModule)
      },

      {
        path: 'venues',

        data: {
          preload: false
        },
        loadChildren: () => import('./pages/venues/venues.module').then((m) => m.VenuesModule)
      },

      {
        path: 'import-sheet',
        canActivate: [spreadsheetGuard],
        data: {
          preload: false,
          breadcrumb: { label: 'breadcrumb.IMPORT-SHEET' },
          requiredFeature: 'spreadsheet'
        },
        loadChildren: () => import('./pages/import-sheet/import-sheet.module').then((m) => m.ImportSheetModule)
      },

      {
        path: 'profile',
        data: { preload: false, breadcrumb: { label: 'breadcrumb.PROFILE' } },
        loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule)
      },

      {
        path: 'users',
        data: { preload: false, breadcrumb: { label: 'breadcrumb.USERS' } },
        loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule)
      },

      {
        path: 'explore',

        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        data: { preload: false, breadcrumb: { label: 'Explore' } },
        loadChildren: () => import('./pages/explore/explore.module').then((m) => m.ExploreModule)
      },
      {
        path: 'analytics',

        data: { breadcrumb: { label: 'breadcrumb.STATISTICS' } },
        loadChildren: () => import('./pages/analytics/analytics.module').then((m) => m.AnalyticsModule)
      },
      {
        path: '404',
        component: ErrorComponent,
        //TODO: Add [error] = 404
        data: {
          breadcrumb: { label: 'breadcrumb.ERROR' },
          type: 'not-found'
        }
      },
      {
        path: 'unauthorized',
        component: ErrorComponent,
        data: {
          breadcrumb: { label: 'breadcrumb.ERROR' },
          type: 'unauthorized'
        }
      }
    ]
  },
  {
    path: 'sign-in',
    component: SigninComponent,
    data: { breadcrumb: { label: 'breadcrumb.SIGN-IN' } }
  },
  {
    path: 'developer',
    loadChildren: () => import('./pages/developer/developer.module').then((m) => m.DeveloperModule)
  },
  {
    path: '**',
    component: ErrorComponent,
    data: {
      breadcrumb: { label: 'breadcrumb.ERROR' },
      type: 'not-found'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
