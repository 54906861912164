import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from '~services/config/config.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(public configService: ConfigService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {};
    if (
      (httpRequest.url.includes(this.configService.get('API_CONFIG').API_URL) ||
        httpRequest.url.includes(this.configService.get('API_CONFIG').PRINT_URL)) &&
      !!localStorage.getItem('access_token')
    ) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    }

    return next.handle(httpRequest.clone({ setHeaders: headers }));
  }
}
