import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EnsureModuleLoadOnceGuard } from './guards/ensure-module-load-once.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { ConfigService } from '~services/config/config.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LivemapViewStateService } from '~pages/livemaps/components/livemap/view-state/livemap-view-state.service';
import { HelpScoutService } from '~services/help-scout/help-scout.service';
import { LayoutModule } from './layout/layout.module';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

// import { SafeHtmlPipe } from '~pipes/safe-html.pipe';
import { MaterialModule } from '../material.module';
import { GoogleTagManagerService } from '~services/google-tag-manager.service';

@NgModule({
  imports: [
    LayoutModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    MaterialModule
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
      deps: [ConfigService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    LivemapViewStateService,
    HelpScoutService,
    GoogleTagManagerService
  ],
  exports: [LayoutModule, BrowserModule, TranslateModule, MaterialModule]
})
export class CoreModule extends EnsureModuleLoadOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
