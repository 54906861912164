import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-auto-open-menu',
  templateUrl: './auto-open-menu.component.html',

  animations: [
    trigger('slideFade', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [style({ opacity: 0, transform: 'translateY(-3%)' }), animate('200ms ease-out')]),
      transition(':leave', [animate('150ms ease-in', style({ opacity: 0, transform: 'translateY(-3%)' }))])
    ])
  ]
})
export class AutoOpenMenuComponent {
  hover = false;

  clickContent(event: MouseEvent) {
    event.stopPropagation();

    this.hover = false;
  }
}
