import { Injectable } from '@angular/core';

import { AppConfigService } from './app-config.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  constructor(private appConfig: AppConfigService) {}

  public initializeTagManager(): void {
    const trackerId = this.appConfig.TRACKER_ID;
    if (trackerId) {
      const gtmScript = document.createElement('script');
      gtmScript.type = 'text/javascript';
      gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackerId}`;
      document.head.appendChild(gtmScript);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });

      const gtagScript = document.createElement('script');
      gtagScript.innerHTML = `
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${trackerId}');
      `;
      document.head.appendChild(gtagScript);
    } else {
      console.warn('Google Tag Manager Tracker ID not found');
    }
  }
}
