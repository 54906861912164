import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { UserService } from '~services/user/user.service';
import { NotificationService, NotificationType } from 'src/app/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

export const feedGuard: CanActivateFn = async () => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);
  const notificationService = inject(NotificationService);
  const translateService = inject(TranslateService);

  try {
    const user = await userService.waitForAuthenticated();
    const hasFeature = user.hasFeature('feed');

    if (!hasFeature) {
      notificationService.launchNotification(
        NotificationType.Warning,
        translateService.instant('user.notification.ACCESS-DENIED')
      );

      router.navigate(['/livemaps']);
    }

    return hasFeature;
  } catch (error) {
    return false;
  }
};
