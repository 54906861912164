<div
  id="sticky-banner"
  tabindex="-1"
  class="fixed start-0 top-0 z-50 flex w-full justify-between border-b border-gray-200 bg-gray-50 p-4">
  <div class="flex w-full items-center justify-between">
    <div class="flex items-center justify-center">
      <a href="https://getwemap.com/" target="_blank">
        <img src="/img/logo_wemap_login.svg" alt="Wemap" class="w-28" />
      </a>
    </div>

    <div class="flex items-center justify-center">
      <app-language-selector></app-language-selector>
    </div>
  </div>
</div>

<div class="fixed inset-0 z-10 flex items-center justify-center bg-gray-50">
  <div class="w-full max-w-sm rounded-lg border border-gray-300 bg-gray-50 p-4 shadow sm:p-6 md:p-8">
    <div *ngIf="!isSSO; then localBlock; else ssoBlock"></div>

    <!-- Sign In -->
    <ng-template #localBlock>
      <form *ngIf="!forgotPassword" #signinForm="ngForm" (ngSubmit)="loginLocal(signinForm)" class="space-y-6">
        <h5 class="text-md font-medium text-gray-900">
          {{ 'user.signin.TITLE' | translate }}
        </h5>

        <div>
          <label for="username" class="mb-2 block text-sm font-bold text-gray-900">Email</label>
          <input
            id="username"
            type="email"
            name="username"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
            placeholder="email@example.com"
            ngModel
            required />
        </div>

        <div>
          <label for="password" class="mb-2 block text-sm font-medium text-gray-900">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            placeholder="•••••••••••"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
            ngModel
            required />
        </div>

        <div class="flex items-start">
          <div class="flex items-start">
            <button
              type="button"
              (click)="isSSO = !isSSO"
              class="ms-auto cursor-pointer text-sm font-medium text-gray-900 hover:underline">
              {{ 'user.signin.LOGIN_WITH_SSO' | translate }}
            </button>
          </div>
          <button
            type="button"
            (click)="forgotPassword = !forgotPassword"
            class="ms-auto cursor-pointer text-sm text-[#2f7ce1] hover:underline dark:text-blue-500">
            {{ 'user.signin.FORGOT_PASSWORD' | translate }}
          </button>
        </div>

        <button
          type="submit"
          [disabled]="!signinForm.valid || loginClicked"
          class="w-full rounded-lg bg-[#2f7ce1] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-default disabled:bg-blue-300">
          {{ 'user.signin.LOGIN' | translate }}
        </button>

        <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
          {{ 'user.signin.NOT_REGISTERED' | translate }}
          <a
            href="https://getwemap.com/contact/"
            target="_blank"
            class="text-[#2f7ce1] hover:underline dark:text-blue-500">
            {{ 'user.signin.CREATE' | translate }}
          </a>
        </div>
      </form>

      <form *ngIf="forgotPassword" #forgotForm="ngForm" (ngSubmit)="resetPassword(forgotForm)" class="space-y-6">
        <h5 class="text-md font-medium text-gray-900">Reset password</h5>

        <div>
          <label for="email" class="mb-2 block text-sm font-bold text-gray-900">Email</label>
          <input
            type="email"
            name="email"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
            placeholder="email@example.com"
            ngModel
            required />
        </div>

        <div class="space-y-2">
          <button
            type="submit"
            [disabled]="!forgotForm.valid"
            class="w-full rounded-lg bg-[#2f7ce1] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-default disabled:bg-blue-300">
            Reset
          </button>

          <button
            type="submit"
            (click)="forgotPassword = !forgotPassword"
            class="w-full rounded-lg px-5 py-2.5 text-center text-sm font-medium text-[#2f7ce1] hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300">
            {{ 'common.BACK' | translate }}
          </button>
        </div>
      </form>
    </ng-template>

    <ng-template #ssoBlock>
      <form #ssoForm="ngForm" (ngSubmit)="redirectSSO(ssoForm)" class="space-y-6">
        <h5 class="text-md font-medium text-gray-900">
          {{ 'user.signin.LOGIN_WITH_SSO' | translate }}
        </h5>

        <div>
          <label for="domaine" class="mb-2 block text-sm font-bold text-gray-900">Domaine</label>
          <input
            type="text"
            name="domaine"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
            ngModel
            required />
        </div>

        <div class="space-y-2">
          <button
            type="submit"
            [disabled]="!ssoForm.valid || loginClicked"
            class="w-full rounded-lg bg-[#2f7ce1] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-default disabled:bg-blue-300">
            {{ 'user.signin.LOGIN' | translate }}
          </button>

          <button
            type="submit"
            (click)="isSSO = !isSSO"
            class="w-full rounded-lg px-5 py-2.5 text-center text-sm font-medium text-[#2f7ce1] hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300">
            {{ 'common.BACK' | translate }}
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<div
  id="bottom-banner"
  tabindex="-1"
  class="fixed bottom-0 start-0 z-50 flex w-full justify-between border-t border-gray-200 bg-gray-50 p-2">
  <div class="w-full">
    <app-footer></app-footer>
  </div>
</div>

<script>
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
</script>
