import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Full page
import { ErrorComponent } from './error/error.component';

import { SigninComponent } from './signin/signin.component';
import { LanguageSelectorComponent } from './languageSelector/languageSelector.component';
import { NotificationComponent } from './notification/notification.component';

import TRANSLATIONS_FR from '../l10n/fr.json';
import TRANSLATIONS_EN from '../l10n/en.json';

import { CoreModule } from './core/core.module';
import { PipeModule } from '~shared/pipes/pipe.module';
import { HelpScoutService } from '~services/help-scout/help-scout.service';
import { GoogleTagManagerService } from '~services/google-tag-manager.service';

@NgModule({
  declarations: [AppComponent, LanguageSelectorComponent, NotificationComponent, SigninComponent, ErrorComponent],
  imports: [
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    DragDropModule,
    ReactiveFormsModule,
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    PipeModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    translateService: TranslateService,
    helpScoutService: HelpScoutService,
    googleTagManagerService: GoogleTagManagerService
  ) {
    helpScoutService.initHelpScout();
    googleTagManagerService.initializeTagManager();

    translateService.setTranslation('en', TRANSLATIONS_EN);
    translateService.setTranslation('fr', TRANSLATIONS_FR);

    const locale = localStorage.getItem('NG_TRANSLATE_LANG_KEY') || navigator.language;
    if (locale !== undefined && locale.match(/^fr/i) !== null) {
      translateService.use('fr');
    } else {
      translateService.use('en');
    }

    dayjs.locale(translateService.currentLang);

    translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('NG_TRANSLATE_LANG_KEY', event.lang);

      dayjs.locale(event.lang);
    });
  }
}
