<div class="relative" (mouseenter)="hover = true" (mouseleave)="hover = false">
  <button mat-button class="w-24" style="color: white">
    <ng-content select="[trigger]"></ng-content>
  </button>

  <div
    tabindex="0"
    @slideFade
    *ngIf="hover"
    class="mat-elevation-z8 absolute z-10 mt-1 min-w-[150px] overflow-hidden rounded-[4px] bg-white text-sm"
    (click)="clickContent($event)"
    (keydown.enter)="hover = false">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
