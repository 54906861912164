import { Injectable } from '@angular/core';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import { DialogService } from '../../services/dialog/dialog.service';
import { DialogTutorialComponent } from '~dialogs/tutorial/tutorial.component';

@Injectable({ providedIn: 'root' })
export class TutorialService {
  constructor(public dialog: DialogService) {}

  openTutorial(step = 1) {
    this.dialog.open(DialogTutorialComponent, {
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        step
      }
    });
  }
}
