{
  "portal": {
    "portal-form": {
      "CREATE-PORTAL": "Paramètres du portail",
      "ADD-LIVEMAP": "Ajouter une carte",
      "HEADER": "En-tête",
      "ADD-LOGO": "Ajouter un logo",
      "INTRO": "Introduction",
      "TITLE": "Titre",
      "SUBTITLE": "Sous-titre",
      "ADD-BACKGROUND": "Insérer une image d'arrière-plan",
      "SEE-LIVEMAP": "Voir la carte",
      "NO-LIVEMAP": "Aucune carte ajoutée",
      "SAVE": "Sauvegarder"
    },
    "portals": {
      "SEARCH-PORTAL": "Rechercher dans les portails...",
      "TITLE": "Titre",
      "SHARE": "Partager",
      "SHARE-PORTAL": "Partager le portail",
      "MANAGE-LIVEMAP": "Gérer les cartes",
      "MAP-COUNT": "Nombre de cartes",
      "INTRO-HELP": "Dans cet onglet vous pouvez gérer les portails créés et les modifier",
      "DELETE-PORTAL": "Supprimer le portail",
      "DELETE-PORTALS": "Supprimer les portails",
      "REALLY-DELETE": "Voulez-vous vraiment supprimer le portail",
      "REALLY-BULK-DELETE": "Voulez vous vraiment supprimer",
      "VISIT": "Visiter le portail",
      "PORTAL": "portail"
    },
    "notification": {
      "PORTAL": "Le portail '",
      "PORTAL-MAPS": "Les cartes du portail '",
      "SAVED": "' a été sauvegardé.",
      "CREATED": "' a été créé",
      "ERROR-GETTING-PORTAL": "Une erreur est survenue au moment de récupérer le portail.",
      "ERROR-GETTING-PORTALS": "Une erreur s'est produite lors de l'obtention des portails.",
      "ENTER-TITLE": "Veuillez entrer un titre",
      "SELECT-MAPS": "Veuillez sélectionner quelques cartes",
      "UPLOAD-BANNER": "Veuillez télécharger une image de bannière",
      "ERROR-DELETING": "Une erreur est survenue au moment de supprimer le portail - ",
      "ERROR-DELETING-SELECTION": "Une erreur est survenue au moment de supprimer les portails.",
      "DELETING-SELECTION": "Les portails ont été supprimée avec succès.",
      "UPDATED": "' ont été mis à jour avec succès",
      "DELETED": "' a été supprimé avec succès",
      "ERROR-CREATING": "Une erreur est survenue au moment de créer le portail."
    }
  },
  "geoentity": {
    "STYLE": "Style",
    "FILL": "Remplissage",
    "FILL-COLOUR": "Couleur du remplissage",
    "FILL-OPACITY": "Opacité du remplissage",
    "STROKE": "Contour",
    "STROKE-COLOUR": "Couleur du trait",
    "STROKE-OPACITY": "Opacité du trait",
    "STROKE-WIDTH": "Épaisseur du trait",
    "STROKE-FORM": "Forme du trait",
    "UNIFORM": "Uniforme",
    "DOTTED": "Pointillé",
    "DASH": "Tiret",
    "SPACE": "Espace",
    "PERSONALIZE": "Personnalisez les géoentités"
  },
  "trip": {
    "trips": {
      "SEARCH": "Rechercher",
      "SEARCH-PPS": "Rechercher dans les parcours..."
    },
    "trip-form": {
      "NAME": "Nom",
      "TAGS": "Tags",
      "DESCRIPTION": "Description",
      "LINKED-LISTS": "Liste liée",
      "ADD-TAGS": "Ajouter des tags",
      "ADD": "Ajouter un parcours",
      "ADD-WAYPOINT": "Ajouter une étape",
      "ADD-WAYPOINTS": "Ajouter des étapes",
      "HOW-TO-ADD": "Comment voulez-vous ajouter des étapes ?",
      "FILE-FORMAT": "Seuls les formats GPX, GEOJSON, KML d'une taille maximale de 24MB sont acceptés",
      "ERROR-NAME": "Ajouter un nom à votre parcours",
      "MESSAGE-SUCCESS-SAVE": "Le parcours { name } a bien été sauvegardée",
      "MESSAGE-ERROR-SAVE": "Une erreur est survenu lors de la sauvegarde",
      "OPTIONAL": "Optionnel",
      "WAYPOINTS": "Étapes",
      "INFO-UNIQUE-FEATURE": "Vous ne pouvez éditer qu'une seule entité",
      "INFO-EDITION": "Vous devez créer un parcours pour effectuer cette action",
      "WAYPOINT-INSTRUCTION": "Ajouter une étape en cliquant sur la carte",
      "WAYPOINT-EDIT": "Éditer les coordonnées de l'étape en cliquant sur la carte",
      "WAYPOINT-EDIT-CONTENT": "Éditer la description",
      "WAYPOINT-BTNS": {
        "XLSX": "Importer un fichier xlsx",
        "LIST": "Importer une liste",
        "POINT": "Importer un point",
        "MANUAL": "Créer une étape manuellement"
      },
      "IMPORTED-LIST": "La liste vient d'être importée.",
      "IMPORTED-XLSX": "Le fichier xlsx vient d'être importé.",
      "GENERATE-TRACE": "Générer le tracé",
      "EDIT-TRACE": "Edition du tracé",
      "HOW-TO-TRACE": "Comment voulez-vous générer le tracé ?",
      "TRACE-BTNS": {
        "AUTO": "Calcul automatique à partir des étapes",
        "MANUAL": "Édition du tracé",
        "IMPORT": "Importer un fichier (GPX, GEOJSON, KML)"
      },
      "ORDERING": "Numéroter les étapes",
      "INFO-ALREADY-TRACE": "Un tracé existe déjà, il sera écrasé si vous continuez."
    },
    "modal-waypoint": {
      "WAYPOINT": "Étape",
      "TITLE-CREATION": "Création d'une étape",
      "TITLE-UPDATE": "Modifier l'étape",
      "WAYPOINT-TITLE": "Titre",
      "WAYPOINT-DESCRIPTION": "Description",
      "WAYPOINT-IMAGE": "Image"
    }
  },
  "area": {
    "areas": {
      "SEARCH": "Rechercher",
      "SEARCH-PPS": "Rechercher dans les zones..."
    },
    "area-form": {
      "DESCRIPTION": "Description",
      "NAME": "Nom",
      "TAGS": "Tags",
      "LINKED-LISTS": "Liste liée",
      "EDIT-POLYGONE": "Editer la zone",
      "ERROR-NAME": "Ajouter un nom à votre zone",
      "MESSAGE-SUCCESS-SAVE": "La zone { name } a bien été sauvegardée",
      "MESSAGE-ERROR-SAVE": "Une erreur est survenu lors de la sauvegarde",
      "INFO-MSG": "Veuillez ajouter une zone",
      "INFO-UNIQUE-FEATURE": "Vous ne pouvez éditer qu'une seule entité",
      "INFO-EDITION": "Veuillez créer une zone pour le sauvegarder"
    }
  },
  "pinpoint": {
    "pinpoint-form": {
      "LINKED-LIST": "Liste liée",
      "MEDIA-CREDITS": "Crédits media",
      "TYPE": "Type",
      "EXTERNAL-ID": "Id externe",
      "OUTDOOR": "Pinpoint extérieur",
      "CATEGORY": "Icône",
      "PICTURE": "Image",
      "VIDEO": "Vidéo",
      "LINKED-LISTS": "Liste liée",
      "ONLY-PIC": "Seuls les formats JPEG et PNG d'une taille maximale de 24 MB sont supportés.",
      "REMOVE": "Supprimer",
      "CORRECT-MEDIA": "Veuillez télécharger un fichier image au bon format.",
      "FILE-SIZE": "Veuillez vous assurer que la taille du fichier est inférieure à 24 MB.",
      "ONLY-VID": "Seuls les formats vidéo MOV et MP4 d'une taille maximale de 24 MB sont supportés.",
      "ONLY-YOUTUBE": "Seules les vidéos Youtube, Dailymotion et Vimeo sont supportées.",
      "NAME": "Nom",
      "ADDRESS": "Adresse",
      "DESCRIPTION": "Description",
      "TAGS": "Tags",
      "LINK": "Connecter ce point à un flux",
      "ENTER-COORDINATES": "Pour ajouter un point, vous pouvez entrer ses coordonnées ou directement",
      "CLICK-ON-MAP": "cliquer sur la carte",
      "LOCATION": "Localisation",
      "PLACE-ADDRESS": "Adresse, lieu...",
      "LINK-URL": "Lien",
      "COUNTRY": "Code pays",
      "PHONE": "Téléphone",
      "FACEBOOK-URL": "Page facebook",
      "TWITTER-URL": "Page twitter",
      "OPENING-HOUR": "Horaires d'ouverture",
      "MONDAY": "Lundi",
      "TUESDAY": "Mardi",
      "WEDNESDAY": "Mercredi",
      "THURSDAY": "Jeudi",
      "FRIDAY": "Vendredi",
      "SATURDAY": "Samedi",
      "SUNDAY": "Dimanche"
    },
    "pinpoint": {
      "OR-COORD": "ou coordonnées",
      "OR-ADDRESS": "ou adresse",
      "ADD-IMAGE": "Ajouter une image",
      "IMAGE": "Une image",
      "ADD-VIDEO": "Ajouter une vidéo",
      "VIDEO": "Une vidéo",
      "ADD-DESC": "Ajouter une description",
      "CHANGE-IMAGE": "Changer l'image",
      "CHANGE-VIDEO": "Changer la vidéo",
      "REMOVE-IMAGE": "Retirer l'image",
      "CREATE-CAROUSEL": "Créer un carousel",
      "CAROUSEL-IMAGES": "Carousel d'images",
      "CAROUSEL-IMAGES-DESC": "Ajouter des images pour créer un carousel",
      "ADD-EXTRA-MEDIA": "Ajouter des images",
      "NO": "No",
      "S": "s",
      "FEEDS-LINKED": "Flux connectés",
      "FEED": "flux",
      "COMMENTS": "Commentaires",
      "INTRO-HELP": "Cliquez sur le bouton + pour ajouter une liste liée au point ou choisissez en une existante",
      "SEARCH": "Rechercher",
      "NO-COMMENTS": "Aucun commentaire à afficher.",
      "MORE": "Plus",
      "CHANGE-STATUS": "Changer le statut",
      "SEARCH-COMMENTS": "Rechercher dans les commentaires...",
      "ADD-TO-LIST": "Ajouter à une liste",
      "ADD-EVENT": "Ajouter un évènement",
      "REMOVE-FROM-LIST": "Retirer d'une liste",
      "ICON": "Icône",
      "THUMBNAIL": "Miniature",
      "LINKED-EVENTS": "Évènements liés",
      "NO-LINKED-EVENTS": "Aucun évènement lié"
    },
    "pinpoints": {
      "PRINT": "Imprimer le point",
      "PPS": "Points",
      "CHANGE-CAT": "Changer l'icône",
      "CHANGE-STATUS": "Changer le statut",
      "PUB-SELE": "Publier la sélection",
      "DRAFT-SELE": "Passer la sélection en brouillon",
      "ADD-SELE-TO-LIST": "Ajouter la sélection à une liste",
      "REMOVE-SELE-FROM-LIST": "Retirer la sélection de la liste",
      "DOWNLOAD-CSV": "Télécharger en tant que CSV",
      "DOWNLOAD-GEOJSON": "Télécharger en tant que GeoJSON",
      "ADD-TAGS": "Ajouter des tags",
      "SEARCH": "Rechercher",
      "SEARCH-PPS": "Rechercher dans les points...",
      "INTRO-HELP": "Dans cet onglet vous pouvez gérer les points créés manuellement ou qui ont été automatiquement ajoutés (d'un import ou d'une app).",
      "CHANGE-TYPE": "Changer le mode d'affichage",
      "PUBLISH": "Publier",
      "DRAFT": "Passer en brouillon",
      "PREVIEW": "Aperçu",
      "DUPLICATE": "Dupliquer",
      "ADD-FIELD": "Ajouter une colonne",
      "LAT-LNG": "Avec latitude / longitude",
      "PUBLIC": "Rechercher tous les points publics",
      "HASHTAG": "Rechercher par tag",
      "LOCATION": "Rechercher par localisation",
      "ADDRESS": "Avec une adresse",
      "EDIT-DESCRIPTION": "Editer la description",
      "ADD-ROW": "Ajouter une ligne",
      "DRAFT-PINPOINTS": "Points sans localisation (brouillon)",
      "ADVANCED": "Options avancées"
    },
    "modal": {
      "modal-edit-description": {
        "EDIT-DESCRIPTION": "Editer la description",
        "FIELDS": "Champs",
        "DESCRIPTION": "Description",
        "CUSTOM-DESCRIPTION": "Personnalisation de la description",
        "WARN-FIELD": "Attention, si un des champs n'est pas présent dans la description, alors celui-ci sera supprimé de vos points."
      },
      "modal-add-feed-to-pinpoint": {
        "ADD-FEED": "Ajouter un flux",
        "ADD-FEED-TO-PP": "Ajouter un flux à un point",
        "FEED-TYPE": "Type de flux",
        "FEED-NAME": "Nom",
        "FEED-URL": "URL"
      },
      "modal-add-tags-to-pinpoints": {
        "ADD-TAGS-TO-PP": "Ajouter des tags au point",
        "ADD-TAGS-TO-PPS": "Ajouter des tags aux points",
        "TAGS": "Tags",
        "TAGS-PLACEHOLDER": "Utilisez \" , \" pour séparer les tags ..."
      },
      "modal-change-type": {
        "PP-TYPE": " Type d'affichage",
        "TYPE": "Type",
        "ICON": "Icône",
        "THUMBNAIL": "Miniature"
      },
      "modal-delete-pinpoint": {
        "DELETE-PP": " Supprimer un point",
        "REALLY-DELETE": "Voulez-vous vraiment supprimer ce point ?",
        "REALLY-DELETE-NAMED": "Voulez-vous vraiment supprimer le point"
      },
      "modal-delete-pinpoints": {
        "DELETE-PPS": " Supprimer des points",
        "REALLY-DELETE": "Voulez-vous vraiment supprimer ce",
        "REALLY-DELETES": "Voulez-vous vraiment supprimer ces",
        "PP": "point",
        "S": "s"
      },
      "modal-edit-feed": {
        "EDIT-FEED": "Editer le flux",
        "FEED-NAME": "Nom",
        "FEED-URL": "URL"
      },
      "modal-livemap-pinpoint": {
        "MAP-PP": "Cartes auxquelles appartient le point"
      },
      "modal-picture-pinpoint": {
        "PP-PIC": "Image du point",
        "PP-VID": "Vidéo du point"
      },
      "modal-share-pinpoint": {
        "SHARE-PP": "Partager le point",
        "PREVIEW": "Aperçu"
      },
      "modal-get-select-lists": {
        "NO-LISTS": "Aucune liste",
        "SELECT-ALL": "Tout sélectionner",
        "LIST-NAME": "Nom de la liste",
        "SELECT-EXISTING-LIST": "Choisir une liste existante",
        "CREATE-LIST": "Ajouter à une nouvelle liste"
      },
      "modal-select-single": {
        "NO-PPS": "Aucun point à afficher"
      }
    },
    "notification": {
      "POINT": "Le point '",
      "FILL-SCHEDULE": "Veuillez correctement remplir les horaires s'il vous plaît (horaire d'ouverture >= horaire de fermeture).",
      "FILL-MANDATORY": "Veuillez remplir les champs requis s'il vous plaît.",
      "OPENIN-CLOSING-EMPTY": "Les horaires d'ouverture / de fermeture ne doivent pas être vides.",
      "ERROR-FETCHING-ADRESS": "Une erreur est survenue lors de l'extraction de l'adresse du point",
      "ERROR-FETCHING-COORDINATES": "Une erreur est survenue lors de l'extraction des coordonnées du point",
      "ERROR-COORDINATES-NOT-VALID": "Les coordonnées renseignés ne sont pas valide.",
      "ERROR-FETCHING-TIMEZONE": "Une erreur est survenue lors de l'extraction du fuseau horaire du point",
      "ERROR-GETTING-POINT": "Une erreur est survenue lors de la récupération du point.",
      "ERROR-GETTING-POINTS": "Une erreur est survenue lors de la récupération des points.",
      "ERROR-SHARE": "Une erreur s'est produite lors de l'obtention deS liens de partage.",
      "ERROR-UPDATING": "Une erreur est survenue lors de la mise à jour du point.",
      "ERROR-UPDATING-S": "Une erreur est survenue lors de la mise à jour des points.",
      "ERROR-BULK-UPDATE": "Une erreur est survenue lors de la mise à jour des points. { updated } points mise à jour sur un total de { total } points.",
      "ERROR-ADDING": "Quelque chose s'est mal passé lors de l'ajout des points à la liste '",
      "ERROR-REMOVING": "Quelque chose s'est mal passé lors de la suppression des points de la liste '",
      "ERROR-DELETING-LIST": "Quelque chose s'est mal passé lors de la suppression des points de la liste - ",
      "REMOVED": "Les points ont été retirés de la liste '",
      "NEED-NAME": "Le point a besoin d'un nom avant d'être mis à jour",
      "UPDATED": "' a été mis à jour",
      "UPDATED-S": "points ont été mis à jour",
      "CREATED": "' a été créé",
      "DELETED": "' a été supprimé",
      "DELETED-S": "points ont été supprimés",
      "PUBLISHED": "' a été publié",
      "PUBLISHED-S": " points ont été publiés",
      "NEED-COORDINATES": "' a besoin de coordonnées pour être publié.",
      "DRAFT": " a été déplacé dans les brouillons.",
      "DRAFT-S": " points ont été déplacés dans les brouillons.",
      "ADDED": "Les points ont été ajoutés à la liste '",
      "DUPLICATE-LOADING": "Duplication en cours",
      "DUPLICATE-SUCCESS": "Les points ont bien été dupliqués.",
      "DUPLICATE-ERROR": "Une erreur est survenue lors de la duplication des points.",
      "WARNING-NO-MAPPING": "La description va être effacée",
      "PHONE-INFORMATION": "Le champ 'Pays' doit être rempli lors de la saisie d'un numéro de téléphone. Le numéro de téléphone doit comporter le bon nombre de chiffres conformément aux normes du pays indiqué."
    }
  },
  "feed": {
    "feed-form": {
      "TYPE": "Type",
      "NAME": "Nom",
      "URL": "URL",
      "OTHER-OPTIONS": "Autres options",
      "IMPORT-INTERVAL": "Interval de mise à jour",
      "IMPORT-INTERVAL-CUSTOM": "Durée de mise à jour (en secondes)",
      "ICON-OPTIONS": "Choisissez une icône",
      "ADVANCED-OPTIONS": "Options avancées",
      "ONLY-PICTURE": "Importer seulement les éléments du flux qui contiennent une image",
      "AUTO": "Auto",
      "RSS-INFO": "Utiliser l'URL du flux avec http:// ou https://. Format - http://atlasobscura.com.feedsportal.com/c/35387/f/665719/index.rss",
      "TWITTER-INFO": "Utiliser from:nom_utilisateur ou #nom_de_votre_tag. (Pour l'utilisateur https://twitter.com/getwemap, from:getwemap. Pour le hashtag https://twitter.com/hashtag/wemap, entrer  #wemap.)",
      "TWITTER-GEOCODE-INFO": "Limiter les points à une zone en dessinant un cercle sur la carte",
      "FB-INFO": "Utiliser nom_utilisateur. (Pour une page https://www.facebook.com/maaapcompany, entrer maaapcompany). Utiliser groups/1515368048690185/ ou events/521880024642068/ ou pages/Theberry-Alaska/1417997928453880 ou l'url entier https://www.facebook.com/groups/1515368048690185/.",
      "AFP-INFO": "Utiliser un url avec plusieurs critères. (Format - https://api.afp.com/v1/api/search?q=topic:People, https://api.afpforum.com/v1/api/search?q=topic:Vidéos%20Monde&lang=fr)",
      "NYTIMES-INFO": "Utiliser un url avec plusieurs critères. (Format - http://api.nytimes.com/svc/events/v2/listings.json?query=dance&api-key=xxxx, http://api.nytimes.com/svc/mostpopular/v2/mostemailed/all-sections/1.json?api-key=xxxx)",
      "PARI-INFO": "Utiliser un url avec plusieurs critères. (Format - http://api.leparisien.fr/articles?token=xxxx&rubriques=pdcm%3Aoise)",
      "YOUTUBE-INFO": "Utiliser channel/nom_de_votre_chaîne ou votre_recherche ou nom_utilisateur. (Pour la chaîne https://www.youtube.com/channel/UCXwDLMDV86ldKoFVc_g8P0g, entrer channel/UCXwDLMDV86ldKoFVc_g8P0g. Pour rechercher une phrase https://www.youtube.com/results?search_query=paris , entrer paris. Pour l'utilisateur https://www.youtube.com/user/aMOODIEsqueezie, entrer user/aMOODIEsqueezie )",
      "INSTA-INFO": "Utiliser nom_utilisateur ou hashtag/nom_de_votre_tag. (Pour un utilisateur https://instagram.com/wemap, entrer wemap. Pour un hashtag https://instagram.com/explore/tags/getwemap, entrer hashtag/getwemap.)",
      "TOURIN-INFO": "Utiliser un url avec plusieurs critères. (Format - https://wcf.tourinsoft.com/Syndication/3.0/cdt44/fa77e985-81ca-41d3-8ebb-c141357cf37b/Objects)",
      "OPENAGENDA-INFO": "Utiliser un url avec plusieurs critères. (Format - https://openagenda.com/agendas/51780064/events.json?page=1&search%5Bpassed%5D=1)",
      "OPENDATASOFT-INFO": "Utiliser un url. (Format - https://data.iledefrance.fr/api/records/1.0/search/?dataset=ipass-350)",
      "INFOLOCALE-INFO": "Utiliser un url avec plusieurs critères. (Format - http://datainfolocale.opendatasoft.com/api/records/1.0/search/?dataset=agenda_culturel&rows=123)",
      "WCITIES-INFO": "Utiliser un url avec plusieurs critères. (Format - https://dev.wcities.com/V3/event_api/getEvents.php?lat=48.85&lon=2.35&searchFor=rock, http://dev.wcities.com/V3/event_api/getEvents.php?lat=37&lon=-122.4192&start=2017-12-20&end=2017-12-20)",
      "EVENTBRITE-INFO": "Utiliser un url avec plusieurs critères. (Format - /events/search?q=montpellie, /users/9637573053/owned_events, /users/9637573053/bookmarks)",
      "LOCALEO-INFO": "Utiliser un url. (Format - https://grc28.localeo.fr/public/carto/getjsonforplatform/folder/78640/pltf/139/catId/0/ip/a8e860bff1dc9a84fc350b6a50736cf6/key/grc_data-COGINOV)",
      "CEETIZ-INFO": "Utiliser un url avec plusieurs critères. (Format - https://api.ceetiz.com/v1/products?destination=5)",
      "PARTIRENLIVRE-INFO": "Utiliser un url avec plusieurs critères. (Format - https://www.partir-en-livre.fr/wp-json/wp/v2/fdl_event)",
      "SORTIRVOSGES-INFO": "Utiliser un url avec plusieurs critères. (Format - https://www.sitlor.fr/xml/exploitation/listeproduits.asp)",
      "TRIPADVISOR-INFO": "Utiliser un url. (Format - https://api.tripadvisor.com/api/partner/2.0/map)",
      "PARTIRENLIVRE-2020-INFO": "Utiliser un url. (Format - https://www.partir-en-livre.fr/wp-json/wp/v2/fdl_event)",
      "VENDEEGLOBE-INFO": "Utiliser un url. (Format - https://www.vendeeglobe.org/en/api/village/program)",
      "MCBASE-LIEUX-META": "Utiliser un url. (Format - https://cartes.culture.gouv.fr/api/places)",
      "JOTFORM-INFO": "Utiliser un url. (Format - https://eu-api.jotform.com/form/203084041437346/submissions)",
      "DEUTSCHEBAHN-INFO": "Utiliser un url. (Format - https://maps-test.reisenden.info/rimapsapi/0.7/station/poi/indoor/DB/byzoneid?zoneID=1071)",
      "EXCEL-INFO": "Utiliser un url. (Format -https://getwemapms-my.sharepoint.com/:x:/g/personal/admin_getwemapms_onmicrosoft_com/EQunPaVEkptNrs65ErP5-EgBFrFGdzw3J3f6YgnqYKo7VA?e=9PXFez)",
      "LISTSHAREPOINT-INFO": "Utiliser un url. (Format -https://nge1.sharepoint.com/sites/testwemap/Lists/International fr/AllItems.aspx)",
      "PARISJETAIME-INFO": "Utiliser un url. (Format - https://parisjetaime.my.salesforce.com)",
      "FRANCETRAVAIL-INFO": "Utiliser un url. (Format - https://api.pole-emploi.io/partenaire/offresdemploi/v2/offres/search?departement=71&codeROME=K1304%2CK1302%2CK1301%2CJ1301%2CK1305%2CJ1501%2CJ1506%2CM1609)",
      "JSON-HELP": "Connectez votre flux JSON en l'important. Choisissez le tableau principal contenant les valeurs, et liez les aux propriétés des points",
      "MCAD-HELP": "Connectez votre flux MCAD en l'important. Choisissez le tableau principal contenant les valeurs, et liez les aux propriétés des points",
      "BASELIEUX-HELP": "Connectez votre flux depuis l'API en indiquant l'url. (URL format: rootAPI + '/api/places' + query)",
      "XML-HELP": "Connectez votre flux XML en l'important. Choisissez le tableau principal contenant les valeurs, et liez les aux propriétés des points",
      "CONNECT": "Connecter",
      "RG-LOW-VALUE": "Valeur maximum faible affluence",
      "RG-HIGH-VALUE": "Valeur max moyenne affluence",
      "UPDATE": "Mettre à jour",
      "LANGUAGE": "Langue",
      "CENTER": "Centrer",
      "API-KEY": "Clé API",
      "CHOOSE": "Choisir",
      "DRAFT-IMPORT": "Import en brouillon",
      "DRAFT-IMPORT-WARNING": "Aucune données de localisation. Vos points seront créés en tant que brouillons. Voulez-vous continuer ?"
    },
    "feed": {
      "DRAG-DROP": "Glissez et déposez les valeurs dans les propriétés",
      "NAME": "Name",
      "NO-LOC-SUGG": "Aucune adresse trouvée",
      "APP-NAME": "Nom de l'application"
    },
    "modal": {
      "modal-category-feed": {
        "PP-CAT": "Icône du point",
        "NEW-CAT": "Nouvelle icône",
        "OWN-CATEGORY": "Vos icônes",
        "COMMON-CATEGORY": "Icônes"
      },
      "modal-delete-feeds": {
        "DELETE-FEEDS": "Supprimer des flux",
        "REALLY-DELETE": "Voulez-vous vraiment supprimer",
        "FEED": "flux",
        "S": "s"
      }
    },
    "table-view": {
      "USE-GEOCODING": "Utiliser le géocoding pour l'adresse",
      "columns": {
        "NAME": "Nom du point",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "ADDRESS": "Adresse",
        "TAGS": "Tags",
        "ICON": "Icône",
        "IMAGE": "Image",
        "COLUMN": "Colonne"
      }
    },
    "notification": {
      "FEED": "Le feed '",
      "FEED-IMPORT": "L'importation du feed '",
      "NO-DATA-FOUND": "Aucune donnée trouvée.",
      "NO-SHEET-SELECTED": "Aucune feuille sélectionnée.",
      "NO-DATA-IMPORTED": "Aucune donnée importée, votre feed doit déjà être à jour.",
      "ERROR": "Une erreur est survenue lors de la récupération des données : ",
      "ERROR-LOADING": "Quelque chose s'est mal passé lors du chargement de l'url.",
      "NOT-ARRAY": "Le chemin choisi n'est pas un tableau d'objets.",
      "NUMBER-IMPORTED": "{ imported } données sur { total } ont été mise à jour",
      "WARNING-IMPORT-INTERVAL": "L'interval de mise à jour doit être compris entre 1 heure et 1 semaine (3600 secondes et 604800 secondes).",
      "ERROR-GETTING-FEEDS": "Une erreur est survenue lors de la récupération des feeds",
      "ERROR-GETTING-FEED": "Une erreur est survenue lors de la récupération du feed.",
      "ERROR-CREATING": "Une erreur est survenue lors de la création du feed.",
      "IMPORTING": "Importation du feed ",
      "IMPORT-COMPLETE": "' est maintenant complète.",
      "SAVED": "' a été sauvegardé.",
      "BATCH-IMPORTED": "Le premier lot de pinpoints importés à partir de l'application apparaîtra dans quelques minutes.",
      "UPDATING-FEED": "Mise à jour du feed ",
      "UPDATE-DESCRIPTION-DELAY": "Le feed a été mis à jour. Les descriptions de vos pinpoints seront générées dans quelques minutes."
    },
    "error-interceptor": {
      "ERROR-GETTING-FEEDS": "Une erreur est survenue lors de la récupération des type de feeds",
      "ERROR-DELETING": "Une erreur est survenue lors de la suppression du type du feed.",
      "ERROR-GETTING-FEED": "Une erreur est survenue lors de la récupération du type du feed.",
      "ERROR-CREATING": "Une erreur est survenue lors de la création du type du feed.",
      "ERROR-UPDATING": "Une erreur est survenue lors de la mise à jour du type du feed."
    }
  },
  "apps": {
    "feeds": {
      "CONNECT": "Connecter",
      "REQUEST-ACCESS": "Demander l'accès",
      "INTRO-HELP": "Dans cet onglet vous pouvez connecter une application (publique/privée) et charger du contenu dans une liste dédiée."
    },
    "feed-form": {
      "APP": "App",
      "THUMBNAIL": "Afficher un aperçu des photos ou vidéos",
      "ALWAYS-ICON": "Toujours afficher une icone",
      "CHOOSE.PICTURES": "Choisissez vos médias"
    },
    "notification": {
      "REQUEST": "La demande d'accès pour l'application ",
      "SEND-SUCCESSFULLY": " a été envoyé avec succès"
    }
  },
  "livemap": {
    "menu": {
      "MAP-RESUME": "Résumé de la carte",
      "SETTINGS": "Disposition, contrôles et lancement de la carte",
      "MAP-SOURCES": "Contenu de la carte",
      "STYLING": "Mise en forme",
      "SERVICES": "Services, recherche et publicités",
      "NAVIGATION": "Navigation",
      "PRINT": "Impression",
      "STATS": "Statistiques"
    },
    "livemap-help": {
      "DIMENSIONS": "Cette option vous permet d’adapter la largeur et la hauteur de votre carte selon l’espace dans lequel vous souhaitez l’intégrer. Par défaut, la largeur est de 100%",
      "LANGUAGE": "Cette option vous permet de choisir la langue de vos contenus sur la carte",
      "MAP-STYLE": "Cette option vous permet de choisir un fond de carte dans une langue et un style différents, en raster ou en vectoriel",
      "LAYERS": "Cette option vous permet de charger un calque json qui s’affichera par-dessus le fond de carte",
      "LINKS": "Cette option vous permet de gérer le comportement des liens présents dans vos contenus",
      "BOUNDS": "Cette option vous permet de régler le niveau de détails minimum et maximum (niveaux de zoom) de votre carte",
      "NAVIGATION": "Cette option vous permet de désactiver si besoin le drag (cliquer-déplacer) et scroll (zoomer avec la molette de la souris) sur votre carte",
      "SIDEBAR": "Cette option vous permet d'afficher ou cacher la barre latérale, et de sélectionner les contenus à afficher (la source)",
      "PINPOINT-DISPLAYED": "Cette option vous permet de choisir le nombre de points maximum affichés sur la carte. La limite maximale conseillée est 400 points. Votre carte comporte plus de 400 points ? Contactez-nous",
      "CLUSTERS": "Cette option vous permet de regrouper les points trop proches et ainsi optimiser leur affichage sur la carte. Vous pouvez choisir une icône personnalisée, utiliser votre avatar comme icône (par défaut) ou encore afficher le nombre de points agglomérés",
      "CLUSTERS-BACK": "Cette option permet de générer les clusters côté serveur pour améliorer les performances des cartes au trafic élevé. Cela signifie en outre un temps de traitement additionnel toute les heures. A utiliser avec précaution",
      "CLUSTERS-RADIUS": "Cette option permet de définir à partir de quelle distance en pixels l'algorithme agrège les points",
      "MARKERS-SIZE": "Cette option vous permet de définir la taille des points qui apparaissent sur la carte",
      "VISIBLE-FIELDS": "Cette option vous permet de choisir quelles informations vous souhaitez afficher dans la cellule des points",
      "EXPAND-DESCRIPTION": "Cette option vous permet d’enlever le bouton “Lire plus” dans la description du point afin d’afficher l’intégralité du texte",
      "EVENTS": "Cette option vous permet d’optimiser l’affichage d’événements sur votre carte (affichage des dates, horaires...)",
      "LANDING": "Cette option vous permet de définir la zone sur laquelle vous souhaitez arriver à l’ouverture de votre carte. Trois options sont possibles : automatique, autour de vous et zone fixe",
      "INTRO-CARD": "Cette option vous permet de définir des préférences pour filtrer le contenu sur la carte : filtrer par dates, tags, zone géographique..",
      "LOCK": "Cette option vous permet de verrouiller la navigation sur la carte",
      "FULLSCREEN-TAP": "Sur mobile, cette option vous permet d’afficher la carte en plein écran au touché sur celle-ci. (uniquement en intégrant la carte via script)",
      "AUTOPLAY": "Cette option vous permet de déclencher l’ouverture automatique des points, dans une ordre aléatoire",
      "MENU": "Cette option vous permet d’afficher ou de masquer certaines options du menu",
      "ZOOM": "Cette option vous permet d’afficher ou de masquer les boutons “zoom +” et “zoom -” sur la carte",
      "SEARCH": "Cette option vous permet d’afficher ou masquer la barre de recherche sur la carte",
      "SEARCH-GEOCODING": "Cette option vous permet d’activer ou désactiver la recherche par geocoding. Vous pouvez aussi configurer un pays sur lequel cette recherche sera faite.",
      "CALENDAR": "Cette option vous permet de forcer l'activation du filtre par dates qui prend la forme d’un calendrier avec sélection de dates, même sans qu'il n'y est d'évènements",
      "AROUND-ME": "Cette option vous permet d’afficher ou masquer le bouton “Autour de moi” qui zoome sur votre position",
      "TAGS": "Cette option vous permet d’activer ou désactiver le filtre par tags. Si cette option est activée, vous pouvez alors accéder à l’écran de gestion des tags",
      "CONTRIBUTIVE-MAP": "Cette option permet aux utilisateurs de contribuer à votre carte en publiant eux-même leurs points",
      "UBER": "Cette option vous permet d’activer le service Uber qui apparaîtra sur un pétale autour des points. Grâce à ce service, les utilisateurs peuvent chercher un chauffeur Uber",
      "ADWORDS": "Cette option vous permet d’utiliser Google Adwords pour afficher des publicités dans les vues détaillées des points",
      "ARVIEW": "Cette option vous permet d'activer la vue en réalité augmentée sur mobile en inclinant le téléphone verticalement",
      "ARVIEW-RADIUS": "Cette option vous permet de définir la distance maximum à laquelle les pinpoints apparaissent dans la vue de réalité augmentée (en mètres)",
      "ARVIEW-CONFIGURATION": "Cette option vous permet de définir la configuration a utilisé pour la réalité augmentée. Pour la configuration intérieur, la taille des objets en réalité augmentée et les distances de geofencing seront plus petites.",
      "USER-TOUR": "Cette option vous permet d'activer l'aide contextuelle au démarrage de votre carte qui détail ses élèments étape par étape",
      "ADD-PINPOINTS": "Pour ajouter des points à votre liste, faites un clic long sur la carte ou utilisez le champ de recherche.",
      "EDIT-MODE": "Vous pouvez glisser et déposer vos points sur la carte ou déplacer les points existants pour modifier leur emplacement.",
      "EDIT-MODE-TOOLTIP": "Le mode d'édition vous permet de placer des points sur la carte et de les modifier."
    },
    "livemap-form": {
      "NO-LISTS": "Aucune liste",
      "EXTERNAL-SERVICES": "Services externes",
      "GEOFEATURE-EDIT": "Editer les objets",
      "CONFIGURATION": "Configuration de la carte",
      "NAVIGATION": "Navigation",
      "EXTERNAL-ID": "Id externe",
      "COPY-CONFIGURATION": "Copier la configuration d'une carte",
      "TITLE": "Titre",
      "LINKED-LISTS": "Listes associées",
      "ADD-LIST": "Ajouter une liste",
      "ADD-VENUE": "Ajouter une venue",
      "CREATE-CONTENT": "Comment voulez-vous créer votre carte ?",
      "FROM-SCRATCH": "Créer une carte vide",
      "FROM-SCRATCH-TEXT": "Configurer votre carte depuis le début",
      "FROM-MODEL": "Créer à partir d'un modèle",
      "FROM-MODEL-TEXT": "Parcourez vos modèles pour utiliser ses options sur votre nouvelle carte",
      "BROWSE-MODEL": "Parcourir les modèles",
      "TOOLTIP-DISABLE": "Ajoutez une liste à la carte pour activer cette option",
      "TOOLTIP-AR-DISABLE": "Vous n'avez pas accès à cette fonctionnalité",
      "SAVE": "Sauvegarder ma carte",
      "SAVE-AND-GET": "Sauvegarder et obtenir ma carte",
      "SAVE-AS-MODEL": "Sauvegarder en tant que modèle",
      "MENU": "Menu",
      "VISIBLE-FIELDS": "Champs visibles",
      "SIDEBAR-CONTENT": "Panneau latéral",
      "OPENED-SIDEBAR": "Panneau par défaut",
      "MAP-USAGE": "Utilisation de la carte",
      "GEOCODING": "Géocoding",
      "BOUNDS": "Bornes",
      "LINKS": "Liens",
      "CUSTOM-LAYER": "Calque personnalisé",
      "CUSTOM-LAYERS": "Calques personnalisés",
      "TILE-STYLE": "Style de carte",
      "EMPTY-HELP": "Survolez un paramètre pour avoir plus d'informations",
      "DIMENSIONS": "Dimensions",
      "INSTEAD-PIXEL": "Pour 600px, écrire 600",
      "PREVIEW": "Prévisualisation",
      "UPDATE-PREVIEW": "Mettre à jour la prévisualisation",
      "MAP": "Carte",
      "READ-ZENDESK": "Lire sur Zendesk",
      "PP-DISP": "Points affichés",
      "WORLD": "Monde",
      "LANG": "Langue",
      "EN": "Anglais",
      "FR": "Français",
      "DE": "Allemand",
      "ES": "Espagnol",
      "IT": "Italien",
      "ZH": "Chinois",
      "PT": "Portugais",
      "JP": "Japonais",
      "AR": "Arabe",
      "ADD": "Ajouter",
      "PP": "Point",
      "USER": "Utilisateur",
      "WIDTH": "Largeur",
      "HEIGHT": "Hauteur",
      "LANDING": "Zone d'ouverture",
      "AUTO": "Auto",
      "FIXED-AREA": "Zone fixe",
      "EDIT-MODE": "Mode édition",
      "IFRAME": "Iframe",
      "USER-POS": "Position de l'utilisateur",
      "NEW-MAP": "Nouvelle carte",
      "ADD-LAYER": "Ajouter depuis un lien",
      "ADD-ASSET": "Ajouter depuis l'éditeur",
      "UNTITLED-LIVEMAP": "Carte sans nom",
      "CONFIRM-SAVE-MODEL": "Etes vous sur de vouloir sauvegarder la carte",
      "WARNING-MODEL": "Cette opération est irréversible",
      "USE-MODERATIVE": "L'activation de la contribution créera une nouvelle liste où seront stocké les pinpoints de contribution. Voulez-vous associer cette liste à la carte ?",
      "NO-PINPOINT-DRAFT": "Aucun point en brouillon n'est disponible sur cette carte."
    },
    "livemap-tags": {
      "TITLE": "Édition des tags",
      "NAME": "Nom",
      "CATEGORY": "Categorie",
      "COLOR": "Couleur",
      "ICON": "Icône",
      "PREVIEW": "Aperçu",
      "VISIBILITY": "Visibilité",
      "SEARCH-FORM": "Afficher dans le moteur de recherche",
      "FEATURED": "Mettre en vedette",
      "POINT-CARD": "Afficher dans la vue détaillée d'un lieu",
      "EVENT-CARD": "Afficher dans la vue détaillée d'un évènement",
      "POINT-HEADER": "Mettre en avant dans la vue d'un lieu",
      "WELCOME-CARD": "Affiche dans la popin d'introduction"
    },
    "livemap-clustering": {
      "PP-ICON": "Utiliser une photo de profil comme icône",
      "CUSTOM-ICON": "Utiliser une icône personnalisée",
      "CHOOSE": "Choisir",
      "COLOR": "Couleur",
      "CLUSTER": "Utiliser le cercle de cluster"
    },
    "snippet-options": {
      "ADWORDS": "Google Adwords",
      "SET-FIXED-AREA": "Définir la zone fixe",
      "DEFAULT": "Valeur par défaut",
      "DISPLAY-DATE": "Afficher dates de publication",
      "HIDE-TIME": "Masquer les horaires",
      "EVENT": "Priorité aux évènements",
      "UBER": "Service Uber",
      "GEOJSON-LAYERS": "Gérer les calques",
      "NO-LAYERS": "Cette carte n'a aucun calque personnalisé",
      "LAYERS-COUNT": "Cette carte possède {count} calque(s)",
      "STYLE-WIZARD": "Créer un nouveau style",
      "CLUSTERS": "Clusters",
      "USE-CLUSTERS": "Utiliser les clusters",
      "USE-CLUSTERS-BACK": "Utiliser les clusters back-end",
      "CLUSTERS-RADIUS": "Radius des clusters",
      "MARKERS-SIZE": "Taille des marqueurs",
      "INTRO-CARD": "Carte d'introduction",
      "USER-TOUR": "Aide contextuelle",
      "DISPLAY-HELP": "Aide",
      "ZOOM": "Boutons de zoom",
      "FULL-SCREEN": "Plein écran",
      "FEATURED-LIST": "Listes",
      "HOME": "Accueil",
      "LOCATION": "Me géolocaliser",
      "SEARCH": "Recherche",
      "SEARCH-GEOCODING": "Recherche par geocoding",
      "GEOCODING-REGION": "Zone de recherche geocoding",
      "DEFINE-CUSTOM-REGION": "Définir une zone personnalisée",
      "REMOVE-CUSTOM-REGION": "Retirer la zone personnalisée",
      "DRAGGING": "Drag",
      "SCROLL": "Scroll",
      "ROTATION": "Rotation & inclinaison",
      "INITIAL-ROTATION": "Rotation initiale",
      "INITIAL-TILT": "Inclinaison initiale",
      "LOCKED": "Verrouiller",
      "AUTO-PLAY": "Lecture automatique",
      "AUTO-PLAY-TIMOUT": "Temps d'attente avant lecture automatique (ms)",
      "LOCK-AUTO": "Verrouiller la lecture automatique",
      "SHOW-AUTHOR": "Afficher l'auteur",
      "SHOW-ADDRESS": "Afficher l'adresse",
      "SHOW-LISTS": "Afficher les listes liées",
      "SHOW-SIDEBAR": "Afficher la barre latérale",
      "SHOW-PINPOINT-DESC": "Afficher la description du point au clic",
      "SHOW-PINPOINT-MEDIA": "Afficher le media du pinpoint dans la description",
      "ENABLE-PETALS": "Activer les boutons petals",
      "EXPAND-DESCRIPTION": "Étendre la description",
      "FEATURED": "En vedette",
      "SOURCES": "Sources",
      "LINKS-ENABLED": "Liens activés",
      "SAME-WINDOW": "Même fenêtre",
      "TAGS": "Tags",
      "CONTRIBUTIVE-MAP": "Carte contributive",
      "MIN-ZOOM": "Zoom minimum",
      "MAX-ZOOM": "Zoom maximum",
      "SHARE-SNIPPET": "Partager la carte",
      "ADSENSE-ID": "Identifiant Adsense",
      "ADSENSE-SLOT": "Adsense slot",
      "DOUBLE-CLICK-ID": "Double click ID",
      "DOUBLE-CLICK-SLOT": "Double click slot",
      "DOUBLE-CLICK-SIZE": "Double click size",
      "DOUBLE-CLICK-TARGET": "Double click target",
      "ADSENSE-WIDTH": "Largeur Adsense",
      "ADSENSE-HEIGHT": "Hauteur Adsense",
      "FILTER-BY-DATES": "Calendrier",
      "FILTER-BY-DATES-MIN": "Date minimum pour le filtre du calendrier",
      "FILTER-BY-DATES-MAX": "Date maximum pour le filtre du calendrier",
      "MIN-ZOOM-DETAILS": "Par défaut : monde (zoom 2)",
      "MAX-ZOOM-DETAILS": "Par defaut : bâtiments (zoom 18)",
      "CLICK-FULL-SCREEN": "Carte en plein écran au touché (mobile)",
      "DEFAULT-STYLE": "Style par défaut",
      "ARVIEW": "Activer la réalité augmentée (mobile seulement)",
      "ARVIEW-SEARCHRADIUS": "Rayon d'affichage des pinpoints en réalité augmentée",
      "ARVIEW-CONFIGURATION": "Configuration de la réalité augmentée",
      "ARVIEW-TITLE": "Réalité augmentée",
      "INDOOR": "Intérieur",
      "OUTDOOR": "Extérieur",
      "EMBED": "Autoriser le partage de l'EMBED sur la carte",
      "EMBED-SHARE": "Partage de l'EMBED"
    },
    "livemaps": {
      "MAPS": "Cartes",
      "MODELS": "Modèles",
      "SEARCH": "Rechercher",
      "NAME": "Nom",
      "SEARCH-MAPS": "Rechercher dans les cartes...",
      "CREATE-MODEL": "+ Créer un modèle",
      "CREATE-LIVEMAP": "+ Créer une livemap",
      "REFRESH-PREVIEW": "Rafraîchir la miniature",
      "DOWNLOAD-PNG": "Télécharger au format PNG",
      "HASHTAG": "Rechercher par tag"
    },
    "modal": {
      "modal-preview-livemap": {
        "CURRENT-PREVIEW": "Preview actuelle",
        "PREVIEW-LIVEMAP": "Preview de la livemap"
      },
      "modal-delete-livemap": {
        "DELETE-MAP": "Supprimer la carte",
        "DELETE-MAPS": "Supprimer des cartes",
        "DELETE-MODEL": "Supprimer le modèle",
        "REALLY-DELETE": "Voulez-vous vraiment supprimer",
        "MAP": "la carte",
        "MAPS": "cartes",
        "MODEL": "le modèle",
        "DELETE-PP": "Supprimer les points des listes liées à cette carte",
        "DELETE-LIST": "Supprimer les listes liées à cette carte",
        "DELETE-PP-S": "Supprimer les points des listes liées à ces cartes",
        "DELETE-LIST-S": "Supprimer les listes liées à ces cartes"
      },
      "modal-share-livemap": {
        "SHARE-MAP": "Partager la carte",
        "SHORT-LINK": "Lien",
        "SNIPPET": "Librairie javascript Wemap"
      },
      "modal-warning-clustering": {
        "WARNING-CLUSTERING": "Avec l'agrégation des points qui sont proches les uns des autres, votre carte peut paraître encombrée. Vous pouvez définir un nombre maximum de points visibles dans le menu 'Mise en page'."
      },
      "modal-select-model": {
        "SELECT-MODEL": "Choisissez votre modèle"
      }
    },
    "notification": {
      "LIVEMAP": "La carte '",
      "UPDATED-5-MINUTES": "' a été mis à jour. Il faudra 5 minutes pour que les modifications soient visibles.",
      "ENTER-NAME": "Vous devez entrer un nom avant de sauvegarder la carte.",
      "0-100": "La taille doit être compris entre 0 et 100%",
      "ERROR-GETTING-LIVEMAP": "Une erreur est survenue lors de la récupération des cartes.",
      "ERROR-GETTING": "Une erreur est survenue lors de la récupération de la carte.",
      "ERROR-SAVING": "Une erreur est survenue lors de la sauvegarde de la carte : ",
      "ERROR-ADDING": "Quelque chose s'est mal passé lors de l'ajout d'une liste à la carte.",
      "ERROR-UPDATING": "Une erreur s'est produite lors de la mise à jour des options de snippet de code par défaut de la carte '",
      "ERROR-TOKEN": "Il y avait un problème lors de l'obtention de votre token. Veuillez rafraîchir la page.",
      "ERROR-CREATING": "Une erreur s'est produite lors de la création de la carte.",
      "ERROR-UP": "Une erreur s'est produite lors de la mise à jour de la carte.",
      "ERROR-DELETING-S": "Quelque chose s'est mal passé lors de la suppression des cartes",
      "ERROR-DELETING": "Quelque chose s'est mal passé lors de la suppression de la carte '",
      "LIST-ADDED": "La liste a bien été ajouté à la carte.",
      "FROM-MODEL": "a été créé à partir du modèle.",
      "CREATED": "' a été créée.",
      "LIVEMAP-PREVIEW": "L'aperçu de la carte '",
      "PREVIEW": "' sera généré dans quelques minutes",
      "PREVIEW-GENERATED": "' a été généré.",
      "ASSET-ADDED": "La ressource a été ajoutée à la carte.",
      "DEL": " a été supprimée.",
      "DELETED": " cartes ont été supprimées."
    },
    "livemap-editor": {
      "btns": {
        "xlsx": "Importer un fichier xlsx",
        "app": "Connecter une app",
        "manual": "Créer un point manuellement"
      },
      "HOW-TO-ADD": "Comment voulez-vous ajouter des points ?"
    }
  },
  "analytics": {
    "dashboard": {
      "ALL-LIVEMAPS": "Toutes les cartes",
      "TITLE": "Tableau de bord - Cartes",
      "DESCRIPTION": "Données de la carte",
      "GENERATE-REPORT": "Générer un rapport",
      "LOAD": "Charger",
      "YESTERDAY": "Hier",
      "LAST-WEEK": "La semaine dernière",
      "LAST-MONTH": "Le mois dernier",
      "LAST-YEAR": "L'année dernière",
      "BODY-TITLE": "Cartes",
      "AVG-SESSION": "Durée moyenne d'une session",
      "NO-DATA": "Aucune donnée trouvée",
      "UNI-SESS": "Sessions uniques",
      "UNI-VIEWS": "Vues uniques",
      "USER-ACT": "Actions de l'utilisateur",
      "NO-OF-USERS": "Nombre d'utilisateurs",
      "LOCATION": "Position",
      "COUNTRY": "Pays",
      "COUNT": "Total",
      "DEVICE": "Appareil",
      "MARKETING_CHANNEL": "Chaîne marketing",
      "PINPOINT": "Point",
      "ACT-PP": "actions sur le point",
      "LIKE": "Like",
      "SHARE": "Partager",
      "DIRECTION": "Directions",
      "OPEN": "Ouverture",
      "INTRO-HELP": "Consultez toutes les statistiques de vos cartes ici (actions, sessions, vues, utilisateurs...)",
      "PAGEVIEW": "Pages vues par minute",
      "PP-EVENTS": "Actions sur points",
      "EV-EVENTS": "Actions sur évènements",
      "SESSIONS": "Sessions",
      "AVERAGE": "Durée de sessions moyenne",
      "PAGE-VIEWS": "Pages vues",
      "EVENTS-PER-SESSION": "Evenements par sessions",
      "TOTAL-EVENTS-PER-SESSION": "Total d'evenement par session",
      "FILTER-BY-DATE": "Filtrer par date",
      "REFERRER": "Référent",
      "REQUEST-STATISTICS": "Demander l'accès au statistiques",
      "FILTER-BY": "Filtrer par",
      "DATE-RANGE": "Entrez une plage de dates",
      "DAILY-VISITS-TITLE": "Combien d'utilisateurs ont accédé à votre carte ?",
      "REFERRERS-TYPE-TITLE": "Comment avez-vous attiré vos utilisateurs ?",
      "DEVICES-TYPE-TITLE": "Quels sont les principaux types d'appareil utilisés par vos utilisateurs ?",
      "CITY-TITLE": "Où vos utilisateurs se trouvaient-ils ?",
      "MAP": "Carte",
      "CONTENT": "Contenu",
      "SEARCH": "Rercherche",
      "SERVICE": "Service",
      "KIOSK": "Kiosque"
    },
    "livemap": {
      "MAP": "Carte",
      "MAP-OVER": "Aperçu de la carte",
      "LOAD": "Charger",
      "NOT-AVA": "Cette carte n'est pas disponible pour cet utilisateur ou n'existe pas.",
      "TIME-SPENT": "Temps moyen passé",
      "MOST-OPEN": "Point le plus souvent ouvert",
      "NAME": "Nom",
      "COUNT": "Total",
      "MOST-ACTED": "Point le plus exécuté"
    },
    "content": {
      "DAILY-PINPOINT-TITLE": "Suivi quotidien des points : Ouverts et Partagés",
      "OPEN": "Ouverts",
      "SHARE": "Partagés",
      "TOTAL-OPEN": "Total des points ouverts",
      "TOTAL-SHARE": "Total des points partagés",
      "TOTAL-LIKE": "Total des points likés",
      "TOTAL-NAVIGATION": "Total de 'y aller'",
      "TOP-OPEN": "Quels ont été les points les plus populaires ?",
      "TOP-EVENT": "Quels ont été les événements les plus populaires ?"
    },
    "search": {
      "DAILY-SEARCH-TITLE": "Nombre de recherches par jour",
      "TOTAL-TAG": "Total des recherches par tags",
      "TOTAL-QUERY": "Total des recherches par mots clés",
      "TOTAL-FILTER": "Total des recherches par filtres",
      "TOP-TAG": "Quels ont été les tags les plus populaires ?",
      "TOP-QUERY": "Quels ont été les mots clés les plus populaires ?"
    },
    "service": {
      "DAILY-ITINERARY-TITLE": "Suivi quotidien des itinéraires : Lancés, Complétés et Arrêtés",
      "LAUNCHED": "Lancés",
      "COMPLETED": "Complétés",
      "HALTED": "Arrêtés",
      "DAILY-AR-TITLE": "Suivi quotidien des activations et désactivations de la Réalité Augmentée",
      "AR-ENABLED": "Activé",
      "AR-DISABLED": "Désactivé",
      "TOTAL-LAUNCHED": "Total d'itinéraires lancés",
      "TOTAL-COMPLETED": "Total d'itinéraires complétés",
      "TOTAL-HALTED": "Total d'itinéraires arrêtés",
      "TOTAL-AR-ENABLED": "Total de la réalité augmentée activée",
      "TOTAL-AR-DISABLED": "Total de la réalité augmentée désactivée"
    },
    "kiosk": {
      "TOGGLE": "Comparer les données",
      "PLACEHOLDER": "Sélectionnez des kiosques",
      "SEARCH": "Rechercher",
      "SELECT-TOOLTIP": "Pour pouvoir sélectionner un kiosque, vous devez d'abord sélectionner une livemap",
      "TOGGLE-TOOLTIP": "Basculer entre les vues combinées et individuelles des kiosques.",
      "CHART-TITLE": "Suivi quotidien de l'utilisation de kiosque",
      "AVG-TITLE": "Temps d’utilisation moyen par activation",
      "CHART-EXPLANATION": "Pas de kiosque sélectionné. Sélectionnez un ou des kiosques pour voir les données associées.",
      "TOTAL-USER": "Total d'utilisateurs",
      "AVG-TIME": "Temps moyen d'utilisation"
    }
  },
  "blocks": {
    "header": {
      "LOGOUT": "Se déconnecter",
      "SUPPORT": "Support",
      "GETTING-STARTED-GUIDE": "Guide de prise en main",
      "PROFILE": "Profil",
      "TUTORIAL": "Tutoriel",
      "USERS": "Utilisateurs",
      "DOC-DEVELOPERS": "Documentation développeurs",
      "MY-ACCOUNT": "Mon compte"
    },
    "nav": {
      "CREATE": "Créer",
      "MANAGE": "Gérer",
      "EXPLORE": "Explorer",
      "MAP": "Carte",
      "LIST": "Liste",
      "TRIP": "Parcours",
      "AREA": "Zone",
      "POINT": "Point",
      "PORTAL": "Portail",
      "EVENT": "Évènement",
      "ASSET": "Ressources",
      "MY-MAPS": "Mes cartes",
      "MY-LISTS": "Mes listes",
      "MY-POINTS": "Mes points",
      "MY-TRIPS": "Mes parcours",
      "MY-AREAS": "Mes zones",
      "MY-APPS": "Mes apps",
      "MY-PORTALS": "Mes portails",
      "MY-EVENTS": "Mes évènements",
      "MY-ASSETS": "Mes ressources",
      "MY-VENUES": "Mes venues",
      "STATISTICS": "Statistiques",
      "SUPPORT": "Support",
      "VENUES": "Venue"
    }
  },
  "common": {
    "ADD": "Ajouter",
    "ACTIVATED": "Activé",
    "BACK": "Précédent",
    "CANCEL": "Annuler",
    "CHANGE-DESCRIPTION": "Modifier la description",
    "CHANGE": "Changer",
    "CLEAR-SELECTION": "Désélectionner la sélection",
    "CLOSE": "Fermer",
    "COLLECTION-AREAS": "zones",
    "COLLECTION-ASSETS": "ressources",
    "COLLECTION-EVENTS": "événements",
    "COLLECTION-LISTS": "listes",
    "COLLECTION-FEEDS": "flux",
    "COLLECTION-LIVEMAPS": "livemaps",
    "COLLECTION-POINTS": "points",
    "COLLECTION-PORTALS": "portails",
    "COLLECTION-TRIPS": "parcours",
    "COLLECTION-USERS": "utilisateurs",
    "COLLECTION-VENUES": "venues",
    "CONFIRM-DELETE": "Êtes-vous sûr de vouloir le supprimer ?",
    "CREATE": "Créer",
    "UPDATE": "Mettre à jour",
    "DELETE-SELECTION": "Supprimer la sélection",
    "DELETE": "Supprimer",
    "DESCRIPTION": "Ajouter une description",
    "DUPLICATE": "Dupliquer",
    "EDIT": "Editer",
    "ERROR-LIKE": "Une erreur est survenue quand vous avez like",
    "ERROR-PROPERTIES": "Le serveur a retourné une erreur avec ces propriétés :",
    "ERROR": "Une erreur est survenue",
    "HOURS": "Heures",
    "MINUTES": "Minutes",
    "NAME": "Nom",
    "NEXT": "Suivant",
    "NO": "Non",
    "OK": "Ok",
    "OPEN-IN-FULL-PAGE": "Ouvrir en pleine page",
    "OPEN-IN-NEW-PAGE": "Ouvrir dans une nouvelle page",
    "OPTIONAL": "Optionnel",
    "REMOVE-FROM-MAP": "Retirer la localisation",
    "REMOVE-FROM-LIST": "Retirer de la liste",
    "REMOVE": "Retirer",
    "RENAME": "Renommer",
    "SAVE": "Sauvegarder",
    "SEARCH": "Rechercher",
    "SEARCHING": "Recherche",
    "SELECTED": "sélectionné(s)",
    "SHARE": "Partager",
    "SHOW": "Afficher",
    "UP-TO-DATE": "A jour",
    "VISIT": "Voir la carte",
    "YES": "Oui",
    "OR": "Ou",
    "NONE": "Aucun",
    "NO-DATA-LIST": "Actuellement, vous n'avez aucune liste ou votre recherche n'a donné aucun résultat. N'hésitez pas à ajouter votre première liste ou à ajuster vos critères de recherche pour organiser et trouver exactement ce dont vous avez besoin !",
    "NO-DATA-POINT": "Actuellement, vous n'avez aucun point ou votre recherche n'a donné aucun résultat. N'hésitez pas à ajouter votre premièr point ou à ajuster vos critères de recherche pour organiser et trouver exactement ce dont vous avez besoin !",
    "NO-DATA-MAP": "Actuellement, vous n'avez aucune carte ou votre recherche n'a donné aucun résultat. N'hésitez pas à ajouter votre première carte ou à ajuster vos critères de recherche pour organiser et trouver exactement ce dont vous avez besoin !",
    "NO-DATA-TRIP": "Actuellement, vous n'avez aucun parcours ou votre recherche n'a donné aucun résultat. N'hésitez pas à ajouter votre premier parcour ou à ajuster vos critères de recherche pour organiser et trouver exactement ce dont vous avez besoin !",
    "NO-DATA-AREA": "Actuellement, vous n'avez aucune zone ou votre recherche n'a donné aucun résultat. N'hésitez pas à ajouter votre première zone ou à ajuster vos critères de recherche pour organiser et trouver exactement ce dont vous avez besoin !",
    "NO-DATA-PORTAL": "Actuellement, vous n'avez aucun portail ou votre recherche n'a donné aucun résultat. N'hésitez pas à ajouter votre premier portail ou à ajuster vos critères de recherche pour organiser et trouver exactement ce dont vous avez besoin !",
    "NO-DATA-EVENT": "Actuellement, vous n'avez aucun évènement ou votre recherche n'a donné aucun résultat. N'hésitez pas à ajouter votre premier évènement ou à ajuster vos critères de recherche pour organiser et trouver exactement ce dont vous avez besoin !",
    "NO-DATA-ASSET": "Actuellement, vous n'avez aucune ressource ou votre recherche n'a donné aucun résultat. N'hésitez pas à ajouter votre première ressource ou à ajuster vos critères de recherche pour organiser et trouver exactement ce dont vous avez besoin !"
  },
  "list": {
    "list-form": {
      "SETTINGS": "Paramètres de la liste",
      "LIST": "Liste",
      "CREATE": "Créer",
      "NAME": "Nom",
      "CREATE-LIVEMAP": "Créer une livemap associée à cette liste",
      "CREATE-FEED": "Créer une vue tableur pour cette liste"
    },
    "list": {
      "CHANGE-STATUS": "Changer le statut",
      "CREATE-FEED-APP": " app.",
      "CREATE-FEED": "Cette liste a été créée via l'app ",
      "DRAFT": "Brouillon",
      "EDIT-FEED": "Éditer",
      "MAKE-ITINERARY": "Mode itineraire",
      "NO-AUTHOR": "Aucun auteur",
      "NO-NAME": "Sans titre",
      "NO-PREV": "Aucun point à afficher.",
      "NO": "Aucun(e)(s)",
      "OPEN-SPREADSHEET": "Ouvrir en vue tableur",
      "PRINT": "Imprimer la liste",
      "PUBLISHED": "Publié",
      "S": "s",
      "SEARCH-OR-CREATE": "Rechercher ou créer une liste",
      "SEE-MAPS": "Voir ses cartes",
      "SHOW-SOURCE": "Consulter la source",
      "UNDO-ITINERARY": "Mode liste simple ",
      "UNLINK-FEED": "Dissocier",
      "UPDATE-FEED": "Mettre à jour"
    },
    "lists": {
      "LISTS": "Listes",
      "CHANGE-STATUS": "Changer le statut",
      "SEARCH": "Rechercher",
      "NAME": "Nom",
      "AUTHOR": "Auteur",
      "SEARCH-LIST": "Rechercher dans les listes...",
      "INTRO-HELP": "Dans cet onglet vous pouvez gérer les listes créées ou automatiquement générées pendant un import ou une création d'app.",
      "DATE-CREATED": "Date de création",
      "DATE-MODIFIED": "Date de modification",
      "SHARE": "Partager",
      "ADD-POINT": "Ajouter un point",
      "ADD-TO-MAP": "Ajouter à une carte"
    },
    "modal": {
      "modal-change-status": {
        "LISTS-STATUS": "Modifier le statut",
        "STATUS": "Statut",
        "PRIVATE": "Privé",
        "SHARED": "Partagé",
        "PUBLIC": "Public"
      },
      "modal-delete-list": {
        "FEED-LINKED-DELETED": "Le flux connecté à cette liste sera aussi supprimé.",
        "REALLY-DELETE-LIST": "Voulez-vous vraiment supprimer la liste",
        "DELETE-LIST": "Supprimer la liste",
        "DELETE-PP": "Supprimer les points de la liste"
      },
      "modal-delete-lists": {
        "REALLY-DELETE": "Voulez-vous vraiment supprimer",
        "LIST": "liste",
        "S": "s"
      },
      "modal-share-list": {
        "SHARE-LIST": "Partager la liste"
      },
      "modal-show-source": {
        "SHOW-SOURCE": "Source de la liste"
      },
      "modal-share-livemap": {
        "MAPS": "Cartes",
        "LIST-MAPS": "Listes des cartes utilisant cette liste",
        "NO-MAPS": "Aucune carte n'utilise la liste"
      },
      "modal-create-options": {
        "CREATE-CONTENT": "Comment souhaitez-vous créer cette liste ?",
        "CREATED-PPS-BELONG": "Les points créés appartiendront à cette liste.",
        "CONNECT-AN-APP": "Connecter une app",
        "CREATE-PPS-MANUALLY": "Créer des points manuellement",
        "IMPORT-XLSX": "Importer un fichier xlsx"
      }
    },
    "notification": {
      "LIST": "La liste '",
      "UPDATED": "' a été mise à jour",
      "UPDATED-S": "listes ont été mises à jour",
      "CREATED": "' a été créée",
      "ADDED": "La liste a été ajouté a la carte.",
      "DELETED": "' a été supprimée",
      "DELETED-S": "listes ont été supprimées",
      "ERROR-UPDATING": "Quelque chose s'est mal passé lors de la mise à jour de la liste '",
      "ERROR-UPDATING-S": "Quelque chose s'est mal passé lors de la mise à jour des listes - ",
      "ERROR-GETTING": "Une erreur s'est produite lors de l'obtention de la liste.",
      "ERROR-GETTING-S": "Une erreur s'est produite lors de l'obtention des listes.",
      "ERROR-DELETING-S": "Quelque chose s'est mal passé lors de la suppression des listes",
      "ERROR-DELETING": "Quelque chose s'est mal passé lors de la suppression de la liste '",
      "ERROR-ADD-NAME": "Veuillez saisir un nom pour la liste"
    }
  },
  "venue": {
    "venue": {
      "SEARCH-OR-CREATE": "Rechercher ou créer une liste",
      "SEARCH-LIST": "Rechercher dans les venues...",
      "REATE-FEED": "Cette liste a été créée via l'app ",
      "UNLINK-FEED": "Dissocier",
      "SHOW-SOURCE": "Consulter la source",
      "UPDATE-VENUE": "Mettre à jour",
      "NEW-VENUE": " Nouvelle venue",
      "EDIT-VENUE": "Éditer",
      "MAKE-ITINERARY": "Mode itineraire",
      "UNDO-ITINERARY": "Mode liste simple ",
      "NO-NAME": "Sans titre",
      "CHANGE-STATUS": "Changer le statut",
      "SEE-MAPS": "Voir ses cartes",
      "NO-PREV": "Aucun point à afficher.",
      "NO": "Aucun(e)(s)",
      "S": "s",
      "NO-AUTHOR": "Aucun auteur",
      "DRAFT": "Brouillon",
      "PUBLISHED": "Publié",
      "VENUE-NAME": "Nom de la venue",
      "ADD-BUILDING": "Ajouter un bâtiment",
      "IMPORT-FROM-OSM": "Importer depuis OSM",
      "UPDATE-TILESET": "Mettre à jour les tuiles",
      "UPDATING-TILESET": "Mise à jour de {count} set de tuiles",
      "ADD-GRAPH": "Ajouter un graphe",
      "UPLOAD-FILE": "Télécharger un fichier (OSM | GeoJSON)",
      "SEE-BUILDINGS": "Voir les bâtiment",
      "ADDED-SUCCESSFULLY": "Le venue a été ajouté avec succès",
      "ADDED-FAILED": "Le venue n'a pas été ajouté.",
      "UPDATED-SUCCESSFULLY": "Le venue a été mis à jour avec succès",
      "UPDATED-FAILED": "Le venue n'a pas été mis à jour.",
      "DATE-CREATED": "Date de création",
      "DATE-MODIFIED": "Date de modification",
      "SAVE-CARD": "Sauvegarder la carte",
      "TAGS": "Tags"
    },
    "venues": {
      "VENUES": " Mes Venues",
      "VENUE": "Venues",
      "CHANGE-STATUS": "Changer le statut",
      "SEARCH": "Rechercher",
      "NAME": "Nom",
      "AUTHOR": "Auteur",
      "SEARCH-LIST": "Rechercher dans les venues...",
      "INTRO-HELP": "Dans cet onglet vous pouvez gérer les venues créées ou automatiquement générées pendant un import ou une création d'app.",
      "DATE-CREATED": "Date de création",
      "DATE-MODIFIED": "Date de modification",
      "SHARE": "Partager",
      "ADD-BUILDING": "Ajouter un bâtiment",
      "ADD-TO-MAP": "Ajouter à une carte",
      "SEE-GEOFEATURES": "Voir les objets",
      "LINKED-VENUES": "Venues Associés",
      "NO-VENUES": "Aucune venue",
      "ADD-VENUE": "Ajouter une venue",
      "NOT-FOUND": "Aucune venue n'a été trouvé"
    },
    "geofeatures": {
      "DELETE-GEOFEATURES": "Supprimer {count, plural, one {l'objet} other {les objets} }",
      "DELETE-GEOFEATURES-PP-WARNING": "Un point est lié à cet objet. La suppression de cet objet supprimera la localisation de ce point."
    },
    "notification": {
      "ADDED": "L'objet ajouté a la venue avec succès",
      "NOT-ADDED": "L'objet n'a pas été ajouté à la venue",
      "MODIFIED": "{count, plural, one {Un objet a été} other {{count} objets ont été} } modifié avec succès",
      "MERGED": "{count} objets ont été fusionnés avec succès",
      "NOT-MODIFIED": "Erreur survenue lors de modification de l'objet",
      "DUPLICATED": "L'objet a été dupliquée avec succès",
      "NOT-DUPLICATED": "Erreur survenue lors de modification de l'objet",
      "DELETED": "{count} objet(s) supprimé(s)",
      "IMPORT-FROM-OSM-SUCCESS": "L'import depuis OSM a été effectué avec succès",
      "IMPORT-FROM-OSM-ERROR": "Une erreur est survenue lors de l'import depuis OSM",
      "VENUE-DELETED": "La venue a été supprimée avec succès",
      "VENUE-NOT-DELETED": "Erreur survenue lors de supression de la venue ",
      "VENUE-ADDED": "La venue a été ajouté avec succès",
      "VENUE-NOT-ADDED": "Erreur survenue lors de L'ajout de la venue ",
      "ERROR-GETTING-S": "Une erreur est survenue lors de la récupération des venues.",
      "LEVEL-INVALID-FILE-TYPE": "Veuillez importer un fichier GeoJSON valide.",
      "LEVEL-IMPORT-FAILED": "Une erreur s'est produite lors de l'importation du niveau",
      "LEVEL-IMPORT-SUCCESS": "Le niveau a été importé avec succès",
      "GEOFEATURE-IMPORT-SUCCESS": "Les objets ont été importés avec succès",
      "GEOFEATURE-IMPORT-LOADING": "Import des objets en cours",
      "LEVEL-OUTSIDE-BUILDING": "Le niveau ne se trouve pas dans la bâtiment",
      "NOT-DELETED": "Erreur survenue lors de supression de l'objet",
      "LEVEL-ADDED": "Le niveau ajouté a la venue avec succès",
      "LEVEL-NOT-ADDED": "Le niveau n'a pas été ajouté à la venue",
      "LEVEL-MODIFIED": "Le niveau a été modifié avec succès",
      "LEVEL-NOT-MODIFIED": "Erreur survenue lors de modification de le niveau",
      "LEVEL-DELETED": "Le niveau a été supprimée avec succès",
      "LEVEL-NOT-DELETED": "Erreur survenue lors de supression de le niveau",
      "BUILDING-ADDED": "Le bâtiment ajouté a la venue avec succès",
      "BUILDING-NOT-ADDED": "Le bâtiment n'a pas été ajouté à la venue",
      "BUILDING-MODIFIED": "Le bâtiment a été modifié avec succès",
      "BUILDING-NOT-MODIFIED": "Erreur survenue lors de modification de le bâtiment",
      "BUILDING-DELETED": "Le bâtiment a été supprimée avec succès",
      "BUILDING-NOT-DELETED": "Erreur survenue lors de supression de le bâtiment",
      "EMPTY-BUILDING-NAME": "Le nom du bâtiment ne doit pas etre vide",
      "LEVEL-NAME": "Le nom du niveau ne doit pas etre vide"
    },

    "level": {
      "EDIT-LEVEL": "Édition du niveau",
      "CREATE-LEVEL-HELP": "Afin de créer un niveau, ajouter un nom, une valeur et dessiner l'emprise du niveau",
      "ADD-LEVEL": "Ajouter un niveau",
      "ADD-LEVEL-DISABLED": "Vous devez ajouter un batiment avant d'ajouter un niveau",
      "ADD-LEVEL-MANUALLY": "Ajouter un niveau manuellement",
      "ADD-LEVEL-IMPORT": "Importer un niveau depuis un fichier (.geojson)",
      "ADD-LEVEL-CONFIRM-TITLE": "Importer un niveau",
      "ADD-LEVEL-COUNT": "Vous allez importer {count} géofeatures. Continuer ?",
      "DELETE-LEVEL": "Supprimer le niveau",
      "BUILDING-LEVEL": "Niveaux",
      "LEVEL-NAME": "Nom du niveau",
      "LEVEL-VALUE": "Valeur du niveau"
    },
    "building": {
      "CREATE-BUILDING-HELP": "Afin de créer un batiment, ajouter un nom et dessiner l'emprise du batiment",
      "BUILDINGS": "Bâtiments",
      "BUILDING-NAME": "Nom du bâtiment",
      "EDIT-BUILDING": "Édition du bâtiment",
      "ADD-BUILDING": "Ajouter un bâtiment",
      "DELETE-BUILDING": "Supprimer le bâtiment"
    },
    "geofeature": {
      "IMPORT-GEOFEATURE": "Importer des objets",
      "HELP-SNAP": "Activer/désactive le snapping et l'affichage des lignes directrices. Maintenez la touche Alt pour activer/désactiver cette fonctionnalité."
    }
  },
  "import-sheet": {
    "import-sheet": {
      "CHOOSE-SHEET": "Choisir une feuille à importer",
      "IMPORTING": "Import en cours. Vous serez notifié lorsque celui-ci sera terminé, vous pouvez donc naviguer librement sur la plateforme.",
      "FINISH": "Import XLSX terminé.",
      "THEME": "Thème",
      "CUSTOMISATION": "Personnalisation de la description",
      "CUSTOMISATION-STYLE": "Personnalisation des géoentités",
      "VALUE": "Valeur",
      "PREVIEW": "Prévisualisation du résultat",
      "FIELD-SIMPLE": "Texte simple",
      "FIELD-ICON": "Titre avec icône",
      "HIDE-TITLE": "Cacher le titre",
      "SHOW-TITLE": "afficher le titre",
      "FIELD-BUTTON": "Bouton d'action",
      "UPLOAD-DOCUMENT": "Envoyez votre document",
      "HELP-PERMISSION": "Assurez-vous que le document est visible pour les utilisateurs ayant le lien",
      "HELP-TITLE-ONE": "En-tête du document",
      "HELP-TITLE-TWO": "Données du document",
      "HELP-TITLE-THREE": "Permissions du document",
      "HELP-ONE": "Doit commencer à la première ligne de la première colonne",
      "HELP-TWO": "Ne doit contenir aucun espace au début et à la fin des cellules ",
      "HELP-THREE": "Doit contenir une date de début et de fin",
      "HELP-FOUR": "Doit être au format 'yyyy-mm-dd' ou ISO '2016-02-29T18:15:28.762Z'",
      "HELP-FIVE": "Doit être visible par tous les utilisateurs possédant le lien",
      "HERE": "ici",
      "GETTING-STARTED": "Retrouvez notre guide de prise en main",
      "IMPORT": "Importer",
      "FORMAT-FILE-UPLOAD": "Le format du document est incorrect. Veuillez télécharger un document XLSX.",
      "LINK": "Lien",
      "LINK-INFORMATION": "Ce champ doit être une URL complète. Exemple: https://getwemap.com",
      "EXTERNAL-DATA": "Données externes",
      "PHONE": "Téléphone",
      "COUNTRY": "Pays",
      "COUNTRY-INFORMATION": "Le champ pays  doit correspondre au code ISO 3166-1 alpha-2 du pays pour être valide, auquel cas ce champ sera refusé.",
      "OPENING-HOURS": "Horaires d'ouverture",
      "MULTIPLE-PROPERTY": "Vous pouvez ajouter une ou plusieurs valeurs dans cette propriété",
      "ADVANCED-FIELDS": "Champs avancés",
      "NAME": "Nom",
      "ID": "Unique ID",
      "DESCRIPTION": "Description",
      "ALTITUDE": "Altitude",
      "LATITUDE": "Latitude",
      "LONGITUDE": "Longitude ",
      "GEO_ENTITY_SHAPE": "Géo entité",
      "ADDRESS": "Adresse",
      "PP-FEED-TWITTER": "Flux Twitter",
      "PP-FEED-FACEBOOK": "Flux Facebook",
      "PP-FEED-ICAL": "Flux iCal",
      "MEDIA-CREDITS": "Crédits media",
      "PICTURE-OR-VIDEO": "Image ou vidéo",
      "CAROUSEL": "Carousel d'images",
      "CATEGORY": "Icône",
      "START-DATE": "Date de début",
      "END-DATE": "Date de fin",
      "TAGS": "Tags",
      "CHECK-SHEET-RECORD": "Vérifiez le numéro d'enregistrement du document :",
      "IMPORT-AGAIN": "Importer ce document à nouveau après la correction des erreurs",
      "THE": "Les",
      "DESC": "Décroissant",
      "IMPORT-LIST-NAME": "Nom",
      "IMPORT-IN-LIST": "Importer dans une liste",
      "IMPORT-LIST": "Aller à la liste contenant le résultat de l'import :",
      "PP-LOADED-DRAFT": "Le point sera chargé en tant que brouillon dans la liste seulement si il n’a pas de adresse.",
      "ROWS-PUBLISHED": "lignes on été publiées.",
      "OF": "sur",
      "INTRO-HELP": "Dans cet onglet vous pouvez importer votre contenu depuis un fichier .xlsx et définir tous les paramètres de vos points (nom, adresse, description...).",
      "PROPERTY": "Propriété",
      "EDIT-MAPPING": "Editer l'attribution des champs",
      "VALIDATE-MAPPING": "Valider l'attribution des champs",
      "CATEGORY-ICON": "Icône de catégorie",
      "PROPERTIES": "Propriétés",
      "VALUES": "Valeurs",
      "OPTIONAL": "<small> - Optionnel</small>"
    },
    "modal": {
      "modal-category-icon": {
        "CATEGORY-TITLE": "Gérer les icones de catégories",
        "ICON": "Icône",
        "NAME": "Nom de catégorie"
      }
    },
    "notification": {
      "FILL-MANDATORY": "Veuillez remplir les champs requis s'il vous plaît.",
      "ERROR-IMPORTING": "Quelque chose s'est mal passé lors de l'importation des points.",
      "ERROR-IMPORTING-PINPOINTS": "Quelque chose s'est mal passé lors de l'importation des points ("
    }
  },
  "user": {
    "modal-request-access": {
      "NOT-PERMISSION": "Vous n'avez pas accès à la",
      "FEATURE": "fonctionnalité",
      "QUESTION": "Voulez-vous demander l'accès ?"
    },
    "profile": {
      "PROFILE-PHOTO": "Photo de profil",
      "BACKGROUND-PHOTO": "Arrière-plan de profil",
      "UPLOAD-PHOTO": "Télécharger une photo",
      "USERNAME": "Nom d'utilisateur",
      "UPDATE": "Mettre à jour",
      "CHANGE-PASS": "Changer le mot de passe",
      "YOUR-PASS": "Mot de passe actuel",
      "NEW-PASS": "Nouveau mot de passe",
      "CONFIRM-PASS": "Confirmer le mot de passe",
      "CHANGE": "Changer",
      "OPT-SETTINGS": "Paramètres optionnels",
      "NOTIFICATION": "Activer les notification d'aide",
      "PASSWORD-RECOMANDATION": " recommandations de mot de passe.",
      "TEN-CHAR": " au moins 10 caractères",
      "LOWER-CHAR": " au moins 1 minuscule",
      "SPECIAL-CHAR": " au moins 1 caractère spécial , e.g., ! &#64; # ?"
    },
    "modal-upload-crop-image": {
      "UPLOAD-PROFILE-PHOTO": "Télécharger une photo de profil",
      "UPLOAD-BACKGROUND-PHOTO": "Télécharger une photo d'arrière plan"
    },
    "users": {
      "USERS": "Utilisateurs",
      "ACCESS_ACCOUNT": "Accéder au compte",
      "FOLLOWERS": "Followers",
      "SUPERVISED_ACCOUNT": "Comptes supervisés ",
      "ALIAS": "Accès autorisés",
      "FOLLOWING": "Following",
      "UNFOLLOW": "Unfollow",
      "FOLLOW": "Suivre",
      "FOLLOW-USERS": "Suivre des utilisateurs",
      "SEARCH-USERS": "Rechercher par nom...",
      "SEARCH-BY-NAME": "Filtrer les résultats par nom ou prénom...",
      "SEARCH-BY-ACCOUNT": "Filtrer les résultats par nom de compte...",
      "NO-RECORDS-FOUND": "Pas d'enregistrements",
      "NO-LINKED-ACCOUNT-FOUND": "Aucun compte lié à votre compte",
      "SEARCH": "Rechercher",
      "PLEASE-SEARCH-NAME": "Tapez un nom dans la barre de recherche !",
      "PASSWORD-NOT-MATCH": "Le mot de passe ne correspond pas à votre nouveau mot de passe",
      "WRONG-CURRENT-PASSWORD": "Le mot de passe actuel est incorrect",
      "TOO-SHORT-PASSWORD": "Le nouveau mot de passe doit contenir au moins 9 caractères",
      "TOO-COMMON-PASSWORD": "Le nouveau mot de passe est trop commun",
      "ENTIRELY-NUMERIC-PASSWORD": "Le mot de passe ne peut pas être entièrement numérique",
      "ALIASES": "Afficher les aliases"
    },
    "signin": {
      "TITLE": "Connectez-vous à Wemap",
      "FORGOT_PASSWORD": "Mot de passe oublié ?",
      "LOGIN": "Connectez-vous",
      "LOGIN_WITH_SSO": "Connexion via SSO",
      "NOT_REGISTERED": "Pas encore inscrit ?",
      "CREATE": "Créer un compte"
    },
    "notification": {
      "PASSWORD-UPDATED": "Votre mot de passe a été mis à jour.",
      "PROFILE-UPDATED": "Votre profil a été mis à jour.",
      "BACKGROUND-UPDATED": "Votre arrière-plan a été mis à jour.",
      "PICTURE-UPDATED": "Votre photo de profil a été mis à jour.",
      "FOLLOW": "L'utilisateur a été suivi avec succès",
      "UNFOLLOW": "L'utilisateur a arrêté d'être suivi avec succès",
      "ACCESS": "Accès à la fonctionnalité '",
      "ACCESS-DENIED": "Vous n'avez pas accès à cette fonctionnalité",
      "REQUESTED": "' requise"
    },
    "error-interceptor": {
      "ERROR-UPDATE": "Une erreur est survenue au moment de modifier votre profil.",
      "ERROR-FOLLOWING": "Une erreur est survenue au moment de suivre l'utilisateur.",
      "ERROR-UNFOLLOW": "Une erreur est survenue au moment d'arrêter de suivre l'utilisateur.",
      "ERROR-GETTING-FOLLOWER": "Une erreur est survenue au moment de récupérer les followers.",
      "ERROR-GETTING-TOKEN": "Une erreur est survenue au moment de récupérer votre token",
      "ERROR-GETTING-USER": "Une erreur est survenue au moment de récupérer l'utilisateur.",
      "ERROR-GETTING-USERS": "Une erreur est survenue au moment de récupérer les utilisateurs.",
      "ERROR-GETTING-ALIAS": "Une erreur est survenue au moment de sélectionner les alias de l'utilisateur."
    }
  },
  "help": {
    "tutorial": {
      "modal-welcome-step1": {
        "WELCOME": "Bienvenue sur la plateforme Wemap Pro !",
        "EASY-POWERFUL": "Wemap Pro est un outil puissant facile à utiliser",
        "BUILD-CONNECTED": "Créez des cartes intelligentes avec vos contenus",
        "FOLLOW": "Suivez ce tutoriel pour savoir comment l'utiliser",
        "MINUTE": " en une minute !"
      },
      "modal-welcome-step2": {
        "CREATE-PLUG": "Créez ou connectez du contenu",
        "WAYS": "Il y a trois façons de créer du contenu sur votre carte.",
        "DOCUMENTS": "Documents",
        "XLSX": "(xlsx)",
        "APPS": "Apps",
        "APPS-EX": "(Twitter, FB...)",
        "CREATE-POINTS": "Créer des points",
        "BY-HAND": "manuellement"
      },
      "modal-welcome-step3": {
        "EDIT-CUSTOMIZE": "Editer et personnaliser des points",
        "CUSTOMIZE-PPS": "Vous pouvez éditer vos points en allant dans l'onglet Listes.",
        "CHANGE": "Changez leur icône de catégorie, titre, tags..."
      },
      "modal-welcome-step4": {
        "SET-MAP": "Paramétrez votre carte",
        "CONTENT-GENERATED": "Une fois que votre contenu est généré, vous pourrez",
        "BASIC-INFO": "remplir les informations de base et ajouter des listes",
        "YOUR-MAP": "à votre carte"
      },
      "modal-welcome-step5": {
        "EMBED-MAP": "Intégrez votre carte sur votre site !",
        "YOUR": "Votre",
        "FULLY-RESPO": " totalement responsive",
        "MAP-READY": " carte est prête !",
        "EMBED": "Pour l'intégrer sur votre site, copiez-collez tout simplement",
        "SNIPPET": "le snippet.",
        "NO-CODE": " Aucune compétence en code n'est requise",
        "CREATE-CONTENT": "Créer du contenu"
      },
      "common": {
        "PREV": "Précédent",
        "NEXT": "Suivant",
        "SKIP": "Passer"
      }
    }
  },
  "selection": {
    "THE": "Les",
    "RESULTS-THIS-PAGE": "résultats de cette page sont sélectionnés.",
    "SELECT-THE": "Sélectionner les",
    "SELECT-MAIN-SEARCH": "résultats de la recherche",
    "THE-FIRST": "Les premiers",
    "RESULTS-MAIN-SEARCH": "résultats de la recherche sont sélectionnés.",
    "PLEASE-WAIT": "Veuillez patienter durant la sélection de tous les résultats."
  },
  "breadcrumb": {
    "ERROR": "Erreur",
    "MAPS": "Cartes",
    "NEW-MAP": "Nouvelle carte",
    "LISTS": "Listes",
    "NEW-LIST": "Nouvelle liste",
    "PPS": "Points",
    "NEW-PP": "Nouveau point",
    "PORTALS": "Portails",
    "NEW-PORTAL": "Nouveau portail",
    "EVENTS": "Évènements",
    "NEW-EVENT": "Nouvel évènement",
    "TRIPS": "Parcours",
    "NEW-TRIP": "Nouveau parcours",
    "AREAS": "Zones",
    "NEW-AREA": "Nouvelle zone",
    "FEEDS": "Flux",
    "APPS": "Apps",
    "NEW-APP": "Nouvelle app",
    "CONNECT-APP": "Connecter une app",
    "APP-TYPES": "Types",
    "NEW-APP-TYPES": "Nouveau type",
    "EDIT-APP-TYPES": "Editer le type",
    "IMPORT-SHEET": "Importer un xlsx",
    "ANALYTICS": "Analytics",
    "MAPS-ANALYTICS": "Cartes",
    "PROFILE": "Profil",
    "USERS": "Utilisateurs",
    "DEVELOPER-SETTINGS": "Paramètres développeur",
    "STATISTICS": "Statistiques",
    "PRINT": "Imprimer",
    "MANAGE-LAYERS": "Gérer les calques",
    "MANAGE-STYLES": "Gérer les styles",
    "ASSETS": "Ressources",
    "NEW-ASSET": "Nouvelle ressource",
    "MAP-PARAMS": "Paramètres de la carte",
    "VENUE": "Venue",
    "NEW-VENUE": "Nouvelle venue",
    "EDIT-GEOFEATURE": "Édition des objets"
  },
  "title": {
    "ERROR": "Erreur",
    "MAPS": "Mes cartes",
    "NEW-MAP": "Nouvelle carte",
    "LISTS": "Mes listes",
    "NEW-LIST": "Nouvelle liste",
    "PPS": "Mes points",
    "NEW-PP": "Nouveau point",
    "PORTALS": "Mes portails",
    "NEW-PORTAL": "Nouveau portail",
    "EVENTS": "Mes évènements",
    "NEW-EVENT": "Nouvel évènement",
    "TRIPS": "Mes parcours",
    "NEW-TRIP": "Nouveau parcours",
    "AREAS": "Mes zones",
    "NEW-AREA": "Nouvelle zone",
    "FEEDS": "Flux",
    "APPS": "Mes apps",
    "NEW-APP": "Nouvelle app",
    "CONNECT-APP": "Connecter une app",
    "EXPLORE": "Explorer",
    "IMPORT-SHEET": "Importer un xlsx",
    "ANALYTICS": "Analytics",
    "PROFILE": "Profil",
    "USERS": "Utilisateurs",
    "DEVELOPER-SETTINGS": "Paramètres développeur",
    "PRINT": "Imprimer",
    "MANAGE-LAYERS": "Gérer les calques",
    "MANAGE-STYLES": "Gérer les styles",
    "ASSETS": "Ressources",
    "NEW-ASSET": "Nouvelle ressource",
    "MAP-PARAMS": "Paramètres de la carte"
  },
  "introcard": {
    "CUSTOMIZE": "Personnaliser",
    "WHEN": "Quand ?",
    "WHEN-TEXT": "Programmez votre visite",
    "WHEN-BTN": "Module \"Quand\": filtrer par dates",
    "AROUND-ME": "Autour de moi",
    "AROUND-ME-TEXT": "Découvrez les évènements proches de vous en ce moment",
    "AROUND-ME-BTN": "Module \"Autour de moi\": géolocalisation",
    "WHERE": "Où ?",
    "WHERE-TEXT": "Tapez votre recherche",
    "WHERE-BTN": "Module \"Où\": filtrer par adresse",
    "TAGS": "Définissez vos préférences",
    "TAGS-TEXT": "Choisissez un ou plusieurs thèmes",
    "TAGS-BTN": "Module \"Tags\": filtrer par mots-clés",
    "UPLOAD": "Télécharger une photo"
  },
  "usertour": {
    "CUSTOMIZE": "Personnaliser",
    "TOOLTIP": "Aides contextuelles",
    "FILTER": "Filtrer",
    "MENU": "Menu",
    "FILTER-BODY": "Cliquez sur \"Partager cette vue\" pour la diffuser vers vos outils préférés ou l'intégrer sur votre site web.",
    "MENU-BODY": "Retrouvez vos sélections, les évènements autour de vous ou activez l'aide.",
    "LIVEBAR-BODY-PINPOINT": "Cliquez sur \"Afficher la liste\" pour voir tous les points.",
    "SHAREVIEW-BUTTON": "Partager la vue",
    "PINPOINT-PETALS": "Fonctionnalités des points"
  },
  "layers": {
    "form": {
      "ALTITUDE": "Altitude",
      "ALTITUDE-INFO": "L'altitude (en mètres) de cette couche, pour les cartes multi-étages.",
      "CLUSTERING": "Clustering",
      "CLUSTERING-INFO": "Utilisez ce layer en tant que clustering hiérarchique.",
      "MAX-ZOOM": "Zoom maximal",
      "MAX-ZOOM-INFO": "Le zoom maximal auquel la couche est visible (exclusif).",
      "MIN-ZOOM": "Zoom minimal",
      "MIN-ZOOM-INFO": "Le zoom minimal auquel la couche est visible (inclusif).",
      "CLICK-ACTION": "Action au clic",
      "HOVER-ACTION": "Action au survol",
      "TYPE": "Type",
      "TYPE-INFO": "Le type de format du layer (GeoJSON, tuiles vectorielles ou image).",
      "URL": "URL",
      "URL-INFO": "L'URL à partir de laquelle récupérer les données du layer."
    }
  },
  "tags": {
    "SEARCH": "Rechercher les tags par slug",
    "CLICKABLE": "Rendre les tags cliquables",
    "NOT-CLICKABLE": "Rendre les tags non cliquables",
    "VISIBILITY": "Changer la visibilité des tags",
    "COLOR": "Changer la couleur des tags",
    "ICON": "Changer l'icon des tags",
    "ACTIVATE": "Activer les tags sur cette carte",
    "TAG-FILTERING-LOGIC": "Logique de filtrage des tags",
    "TAG-FILTERING-AND": "ET",
    "TAG-FILTERING-OR": "OU",
    "TAG-FILTERING-DEFAULT": "Défaut",
    "INTERACTIVE-EXAMPLE": "Voir l'exemple intéractif",
    "OR-LOGIC-LABEL": "Avec une logique en \"OU\", tous les points avec au moins un des tags sélectionnés apparaitront dans les résultats",
    "AND-LOGIC-LABEL": "Avec une logique en \"ET\", les points doivent avoir tous les tags sélectionnés pour apparaître dans les résultats",
    "DEFAULT-LOGIC-LABEL": "Avec une logique de filtrage par défaut, les tags d'une même catégorie se cumulent (OU) et les tags entre les catégories sont exclusifs (ET).",
    "LOGIC-LABEL-START": "Les points avec comme tags",
    "LOGIC-LABEL-END": "apparaîtront dans les résultats",
    "NO-TAG": "Cette carte n'a pas de tags. Vous pouvez en ajouter à vos points et re-synchroniser la liste de tags.",
    "CLEAN": "Supprimer les tags obsolètes",
    "CLEAN-HELP": "Êtes-vous sûr de vouloir supprimer tous les tags obsolètes ? Ceci est irréversible.",
    "ORDER": "Trier les tags par ordre alphabétique",
    "CATEGORY": "Changer la catégorie des tags",
    "CREATE-CATEGORY": "Créer une nouvelle catégorie de tags"
  },
  "print": {
    "IMG-RES": "Resolution de l'image",
    "USE-AS-PREVIEW": "Utiliser en tant que prévisualisation"
  },
  "asset": {
    "NEW-ASSET": "Paramètre de la ressource",
    "INPUT": "Nom de la ressource",
    "LAYER": "Couche de données",
    "STYLE": "Feuille de style",
    "LINK-TO-MAP": "Lier cette ressource à une carte",
    "LINK-STYLE-TO-MAP": "Lier ce style à une carte",
    "LINK-LAYER-TO-MAP": "Lier cette couche à une carte",
    "geojson": {
      "NEW-LAYER": "Nouvelle couche GeoJson",
      "NEW-PROP": "Nouvelle propriété",
      "NEW-VALUE": "Valeur",
      "PROPS": "Propriétés",
      "IMPORT": "Importer depuis un fichier",
      "PROPERTY-EXISTS": "Cette propriété est déjà présente"
    },
    "style": {
      "COLORS": "Couleurs",
      "key": {
        "BACKGROUND": "Intérieur des terres",
        "WATER": "Eaux",
        "ROADS": "Routes",
        "LANDUSE_PARK": "Parcs"
      }
    },
    "list": {
      "NAME": "Nom",
      "HELP-INTRO": "Créez des ressources telles que des styles additionnels ou des couches de données géographiques pour enrichir et personnaliser vos cartes !"
    },
    "modal": {
      "SELECT-ALL": "Sélectionner toute la page",
      "DELETE": "Supprimer une ressource",
      "DELETE-SURE": "Êtes-vous certain de vouloir supprimer la ressource",
      "DELETE-MULTIPLE-SURE": "Êtes-vous certain de vouloir supprimer ces ressources",
      "RENAME": "Renommer une ressource",
      "RENAME-SURE": "Saisissez le nouveau nom de la ressource",
      "UPDATE": "Sauvegarder une ressource",
      "UPDATE-SURE": "Attention, cette ressource va être mise à jour sur toutes vos cartes l'utilisant"
    },
    "create": {
      "BUTTON": "Créer la ressource"
    },
    "notification": {
      "ASSET": "La ressource '",
      "CREATED": "' a été créée",
      "DELETED": "' a été supprimée",
      "DELETED-S": "ressources ont été supprimées",
      "ERROR-GETTING-ASSETS": "Quelque chose s'est mal passé lors de l'obtention des ressources.",
      "ERROR-DUPLICATING": "Une erreur s'est produite lors de la duplication de la ressource '",
      "DUPLICATED": "' a été dupliquée!",
      "ERROR": "Une erreur est survenue.",
      "UPDATED-MESSAGE": " a été mis à jour."
    },
    "error-interceptor": {
      "ERROR-GETTING": "Quelque chose s'est mal passé lors de la récupération d'une ressource.",
      "ERROR-CREATING": "Quelque chose s'est mal passé lors de la création d'une ressource.",
      "ERROR-UPDATING": "Quelque chose s'est mal passé lors de la mise à jour d'une ressource.",
      "ERROR-DELETING": "Quelque chose s'est mal passé lors de la suppression d'une ressource."
    }
  },
  "event": {
    "event": {
      "INTRO-HELP": "Gérez les dates de l'évènement ci-dessous",
      "DELETE-DATE": "Voulez vous vraiment supprimer ces dates ?",
      "DELETE": "Voulez-vous vraiment supprimer l'évènement "
    },
    "events": {
      "INTRO-HELP": "Dans cet onglet vous pouvez gérer les évènements créés manuellement ou qui ont été automatiquement ajoutés (d'un import ou d'une app).",
      "SEARCH-EVENTS": "Rechercher dans les évènements",
      "FROM-DATE": "Du",
      "TO-DATE": "au",
      "SEARCH-LIST": "Rechercher par liste",
      "SEARCH-PINPOINT": "Rechercher par point",
      "SELECT-LIST": "Selectionner une liste",
      "SELECT-PINPOINT": "Selectionner un point"
    },
    "event-form": {
      "CREATE-EVENT": "Paramètre de l'évènement",
      "LINKED-PINPOINT": "Point lié",
      "DATES": "Dates",
      "START-DATE": "Date de début",
      "END-DATE": "Date de fin",
      "SELECT-DATE-RANGE": "Saisissez une plage de dates",
      "INVALID-DATE-RANGE": "La période sélectionnée n'est pas valide, modifiez la date ou l'heure.",
      "START-TIME": "Heure de début",
      "END-TIME": "Heure de fin",
      "ERROR-REQUIRED-START-DATE": "La date de début est requise",
      "ERROR-REQUIRED-END-DATE": "La date de fin est requise",
      "ERROR-REQUIRED-START-TIME": "L'heure de début est requise",
      "ERROR-REQUIRED-END-TIME": "L'heure de fin est requise",
      "ERROR-INVALID-START-DATE": "La date de début n'est pas valide",
      "ERROR-INVALID-END-DATE": "La date de fin n'est pas valide",
      "NAME": "Nom"
    },
    "event-media": {
      "CHANGE": "Changer l'image",
      "REMOVE": "Supprimer l'image"
    },
    "modal": {
      "EVENT-PIC": "Image de l'évènement",
      "REMOVE-PIC": "Retirer l'image ?",
      "delete": {
        "QUESTION": "Voulez-vous vraiment supprimer",
        "DELETE-EVENT": "Supprimer un évènement",
        "EVENT": "l'évènement",
        "EVENTS": "évènements"
      }
    },
    "notification": {
      "EVENT": "L'évènement '",
      "DELETED": "' a été supprimé avec succès",
      "SAVED": "' a été sauvegardé.",
      "CREATED": "' a été créé",
      "PRIVATE-OR-NOT-EXIST": "Cet évènement est privé ou n'existe pas.",
      "ERROR-GETTING": "Une erreur s'est produite lors de l'obtention de l'évènement.",
      "ERROR-GETTING-S": "Une erreur s'est produite lors de l'obtention des évènements.",
      "CROSSING": "Assurez-vous que les plages de dates ne se croisent pas.",
      "ERROR-DELETING": "Quelque chose s'est mal passé lors de la suppression de l'évènement.",
      "INFO-REQUIRED": "Le point, le nom et la date sont nécessaires.",
      "VERIFY-DATE": "Il y a un problème avec vos dates, veuillez vérifier si elles sont bien remplies",
      "ERROR-UPDATING": "Quelque chose s'est mal passé lors de la mise à jour de l'évènement.",
      "ERROR-CREATING": "Quelque chose s'est mal passé lors de la création de l'évènement."
    }
  },
  "developers": {
    "ADD-APP": "Ajouter une application",
    "CREATE-NAME": "Enregistrez une nouvelle application",
    "CREATE-APP": "Enregistrer",
    "REGISTERED-APPS": "Vos applications enregistrées",
    "DOC-TITLE": "Documentation",
    "TOKEN": "Token utilisateur",
    "TOKEN-DESC": "Ce token est celui que vous devez utiliser dans les paramètres lors de l'instanciation d'une livemap depuis notre SDK.",
    "DOC-TEXT": "Créer une application connectée à Wemap permet de publier des livemaps encore plus facilement. Nous mettons à disposition des développeurs nos SDK Android, iOS et Javascript, ainsi qu'une API REST.",
    "DOC-GOTO": "Consulter la documentation"
  },
  "auth": {
    "SENT-EMAIL": "Nous vous avons envoyé un email pour choisir un nouveau mot de passe, veuillez vérifier votre boîte de réception.",
    "ERROR-SENT-EMAIL": "Une erreur s'est produite lors de l'envoi de l'email pour choisir votre nouveau mot de passe. Veuillez réessayer.",
    "ERROR-USERNAME-PASSWORD": "Mauvais nom d'utilisateur ou mot de passe",
    "ERROR-SSO": "Une erreur s'est produite lors de la connexion avec votre compte SSO. Veuillez réessayer.",
    "USER-FAILED": "L'initialisation de l'utilisateur a échoué"
  },
  "page": {
    "GO-BACK": "Retournez à la page de connexion",
    "FOOTER": "Confidentialité et Conditions d'utilisation",
    "OF": "sur"
  },
  "comment": {
    "ERROR-GETTING": "Quelque chose s'est mal passé lors de l'obtention des commentaires.",
    "ERROR-POSTING": "Une erreur s'est produite lors de la publication du commentaire.",
    "FILL": "Saisissez un commentaire valide s'il vous plaît.",
    "SUCCESS": "Le commentaire a été posté avec succès.",
    "DELETE": "Le commentaire a été supprimé avec succès."
  },
  "category": {
    "CATEGORY": "La catégorie '",
    "VALID": "Vous devez sélectionner un fichier valide à télécharger.",
    "DELETED": "' a été supprimé avec succès."
  },
  "upload": {
    "CLICK": "Cliquez ici pour sélectionner un fichier",
    "DROP": "Déposez votre fichier",
    "UPLOAD-FILE": "Télécharger votre fichier"
  },
  "parameters": {
    "WARNING-OVERRIDE": "Attention, cette action est irréversible et va écraser le snippet des cartes choisies.",
    "EXPORT-CONFIGURATION": "Exporter cette configuration vers d'autres cartes"
  },
  "indoor-feature": {
    "notification": {
      "ERROR-GETTING": "Une erreur est survenue lors de la récupération des fonctionnalités intérieures.",
      "ERROR-UPDATING": "Une erreur est survenue lors de la mise à jour de l'objet.",
      "ERROR-ALREADY-LINK": "L'emplacement est déjà lié à un point.",
      "ERROR-BUILDING": "Une erreur est survenue lors de la récupération du bâtiment.",
      "WARNING-BUILDING": "Aucun bâtiment n'a été trouvé pour cette position"
    }
  },
  "error": {
    "NOT-FOUND-CTA": "Retournez à la page d'accueil",
    "NOT-FOUND-TITLE": "La page n'existe pas",
    "NOT-FOUND-TEXT": "La page que vous recherchez n'existe pas. Veuillez réessayer ou contacter le support.",
    "UNAUTHORIZED-CTA": "Retournez à la page d'accueil",
    "UNAUTHORIZED-TEXT": "Vous n'êtes pas autorisé à accéder à cette page. Veuillez réessayer ou contacter le support.",
    "UNAUTHORIZED-TITLE": "Accès refusé"
  },
  "toolbar": {
    "SELECT": "Sélectionner",
    "SELECT-MULTIPLE": "Sélectionner plusieurs",
    "DRAW-POLYGON": "Dessiner un polygone",
    "DRAW-LINE": "Dessiner une ligne",
    "DELETE": "Supprimer",
    "UPLOAD-PLAN": "Importer un plan (.png, .jpg)",
    "DUPLICATE": "Dupliquer",
    "FILTER": "Filtrer",
    "STYLE-FROM-LIVEMAP": "Depuis une livemap",
    "STYLE-FROM-LINK": "Depuis une URL",
    "UPLOAD": "Importer des objets",

    "dialog": {
      "STYLE-URL-TITLE": "Ajouter un style depuis une URL"
    }
  }
}
