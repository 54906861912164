import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { US, FR } from 'country-flag-icons/string/3x2';

@Component({
  selector: 'app-language-selector',
  template: `
    <div class="flex items-center justify-center">
      <button
        class="w-4 cursor-pointer hover:opacity-75"
        *ngIf="translate.currentLang !== 'fr'"
        (click)="setLanguage('fr')"
        [innerHTML]="frFlag | safeHtml">
        FR
      </button>

      <button
        class="w-4 cursor-pointer hover:opacity-75"
        *ngIf="translate.currentLang !== 'en'"
        (click)="setLanguage('en')"
        [innerHTML]="usFlag | safeHtml">
        US
      </button>
    </div>
  `
})
export class LanguageSelectorComponent {
  frFlag: string = FR;
  usFlag: string = US;

  constructor(public translate: TranslateService) {}

  setLanguage(lang: string) {
    this.translate.use(lang);
  }
}
