<div class="relative h-auto min-h-screen w-full" id="app" style="background: white">
  <!-- Navbar -->
  <div *ngIf="showHeaderFooter">
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>
  </div>

  <!-- Content -->
  <div class="flex items-center justify-center pb-16 pt-32">
    <div class="h-full w-[var(--app-width)] min-w-[var(--app-min-width)] max-w-[var(--app-max-width)]">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- Footer -->
  <div class="absolute bottom-0 left-0 right-0 z-50 border border-t-[1px] bg-white p-4" *ngIf="showHeaderFooter">
    <app-footer></app-footer>
  </div>
</div>
