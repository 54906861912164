<section class="w-full text-center">
  <h2 class="text-2xl font-bold">
    {{ 'error.' + type.toUpperCase() + '-TITLE' | translate }}
  </h2>
  <div class="align-center mt-4 flex flex-col justify-center gap-4 rounded-lg bg-blue-50 p-4 text-center text-blue-700">
    <p>{{ 'error.' + type.toUpperCase() + '-TEXT' | translate }}</p>
    <a mat-button color="primary" class="w-fit self-center bg-white p-4" routerLink="/">{{
      'error.' + type.toUpperCase() + '-CTA' | translate
    }}</a>
  </div>
</section>
