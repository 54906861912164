import { Component, OnDestroy } from '@angular/core';
import { AppConfigService } from '~services/app-config.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  title = 'wemap';

  public showHeaderFooter = true;

  constructor(
    private appConfigService: AppConfigService,
    private router: Router
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showHeaderFooter = event.url !== '/sign-in';
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
